import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
    plugins: [createPersistedState({
        storage: window.sessionStorage,
    })],
    state: {
        id: null,
        user_id: null,
        joinday: null,
        mobile: null,
        name: null,
        avatar: null,
        gameLink: null,
        isLoggedIn: false,
        cards: null,
        mobilestore:null,
        emailstore:null,
        zalostore: null,
        frozen: null,
        balance: null,
        withdrawCards: null,
        withdrawCardID: null
    },
    mutations: {
        user_info(state, data) {
            state.id = data.id
            state.user_id = data.user_id
            state.joinday = data.joinday
            state.mobilestore = data.mobilestore
            state.emailstore = data.emailstore
            state.zalostore = data.zalostore
        },
        user_contact(state, data) {
            state.name = data.name
            state.mobile = data.mobile
        },
        user_bal(state, data) {
            state.balance = data
        },
        user_frozen(state, data) {
            state.frozen = data
        },
        user_cards(state, data) {
            state.cards = data
        },
        user_avatar(state, data) {
            state.avatar = data
        },
        user_login(state, data) {
            state.gameLink = data
            state.isLoggedIn = true
        },
        withdraw_cards(state, data) {
            state.withdrawCards = data
        },
        withdraw_cardID(state, data) {
            state.withdrawCardID = data
        }
    },
    actions: {
        saveInfo: ({ commit }, data) => {
            commit('user_info', data)
        },saveBal: ({ commit }, data) => {
            commit('user_bal', data)
        },saveFrozen: ({ commit }, data) => {
            commit('user_frozen', data)
        },
        saveContact: ({ commit }, data) => {
            commit('user_contact', data)
        },
        saveCards: ({ commit }, data) => {
            commit('user_cards', data)
        },
        saveAvatar: ({ commit }, data) => {
            commit('user_avatar', data)
        },
        saveLogin: ({ commit }, data) => {
            commit('user_login', data)
        },
        saveWithdrawCards: ({ commit }, data) => {
            commit('withdraw_cards', data)
        },
        saveWithdrawCardID: ({ commit }, data) => {
            commit('withdraw_cardID', data)
        }
    },
    modules: {}
})
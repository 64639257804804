var CryptoJS = require("crypto-js");
module.exports = {
    baseApi: 'https://uhappy.bestahk.com/bwmodel/api/handle',
    imgBaseApi: '',
    accountAuth: {
        baseApi: 'https://uhappy.bestahk.com/bwmodel/api/salary',
        vendor: '404',
        forgotCodeCMD: '5',
        forgotCMD: '6',
        loginCMD: '2',
        registerCMD: '1',
        personalInfoCMD: '7',
        logoutCMD: '8',
        checkLoginCMD: '3',
        withdrawWalletCMD: '9',
        sendEmailCode: '10',
        checkEmailCode: '11',
        verifyemailCMD:'12',
        verifyemailcodeCMD: '13',
        changepasswordCMD: '15',
        verifysmsCMD:'16',
        verifysmscodeCMD: '14',
        verifyemailcodeBankCMD: '17',
        editZaloCMD: '18',
        editUsernameCMD: '20'

    },
    merchant: '404',
    getBalFrozenSerial: '407',
    gameListSerial: '309',
    openGameSerial: '301',
    getGameLink: '19',
    gamePointsSerial: '304',
    transferSerial: '142',
    walletGameSerial: '141',
    hotGameSerial: '300',
    cardListSerial: '205',
    verificationCodeSerial: '206',
    addBankSerial: '211',
    unbindBankSerial: '208',
    userProfileSerial: '143',
    gameHistorySerial: '128',
    transactionHistorySerial: '601',
    DepositRecordsSerial: '508',
    WithdrawRecordsSerial: '403',
    paymentMethodSerial: '509',
    submitDepositSerial: '500',
    withdrawalSerial: '209',
    submitWithdrawGame: '404',
    changeAvatarSerial: '105',
    messagesSerial: '139',
    loginAuthSerial: '583474942',
    readMessageSerial: '140',
    md5Key: '56add28y2D63Wsd8',
    aes: '6flDs93963F8A25A',
    sign: function(merchant, serial, md5Key) {
        return CryptoJS.MD5(merchant + serial + md5Key).toString()
    },
    encryptAES: function(query, aes) {
        return encodeURIComponent(CryptoJS.AES.encrypt(query, aes).toString())
    }
};

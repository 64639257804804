<template>
    <div class="personal-information">
        <Spinner v-if="isSpinner" :msg='spinMessage' :status="failResponse" />
        <LogOutSpinner v-if="isSpinner2" :msg='spinMessage2' :status="failResponse2" />
        <div class="logout-popup" v-if="logoutPopUp">
            <div class="content">
                <div class="body">
                    đăng xuất
                </div>
                <div class="footer">
                    <ul>
                        <li @click="logoutPopUp=false">hủy bỏ</li>
                        <li @click="logout()">xác nhận</li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="change-avatar" v-if="changeAvatarPopUp">
            <div class="dim" @click="changeAvatarPopUp=false"></div>
            <div class="content">
                <div class="choices">
                    <ul>
                        <li v-for="index in 8" v-bind:key="index">
                            <div class="cont" @click="changeAvatar(index)"
                                v-bind:class="{ selected: selectedImg == index }">
                                <img :src="getImg(index)" alt="">
                                <div class="check"></div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="footer" @click="changeAvatarPopUp=false"><span>hủy bỏ</span></div>
            </div>
        </div>

        <div v-if="content==1">
            <div class="avatar" v-if="$store.state.user_id != null">
                <img :src="getImg(data.avatar)" alt="" v-if="$store.state.avatar != null"
                    @click="changeAvatarPopUp=true">
                <div class="desc">
                    <h4>{{ data.user_id }}</h4>
                    <div class="infocon">
                        <p><span>Gia nhập ngày thứ :</span><span class='infores'>{{ data.joinday }}</span> </p>
                        <!-- <p><span>ID :</span><span class='infores'>{{ data.id }}</span> </p> -->
                        <!-- <p>
                            <span>số điện thoại :</span>
                            <span class='infores' v-if="showmobile">{{ mobiledisplay }}</span>
                            <span v-else class="infores"><button class="infobind"
                                    @click="content=31, phone='', phoneCode='', countDown2=0, countshow2=false">Liên
                                    kết</button></span></p> -->
                        <p><span>E-mail :</span>
                            <span class='infores' v-if="showemail">{{ emaildisplay }}</span>
                            <span v-else class="infores"><button class="infobind"
                                    @click="content=32, email='', emailCode='', countDown=0, countshow=false">Liên
                                    kết</button></span></p>
                        <p><span>Zalo :</span>
                            <span class='infores' v-if="showzalo">{{ zalodisplay }}</span>
                            <span v-else class="infores"><button class="infobind" @click="content=34">Liên
                                    kết</button></span></p>
                    </div>
                </div>
            </div>

            <div class="others">
                <ul>
                    <li>
                        <div class="security" @click="content=2"></div>
                    </li>
                    <!-- <li>
                        <div class="customer"></div>
                    </li> -->
                    <li>
                        <div @click="logoutPopUp=true" class="logout"></div>
                    </li>
                </ul>
            </div>

        </div>
        <div v-if="content==2">
            <div class="return" @click="content=1"></div>

            <div class="selection">
                <ul>
                    <!-- <li><span>Liên kết số điện thoại</span><button
                            @click="content=31, phone='', phoneCode='', countDown2=0, countshow2=false">Liên
                            kết</button></li> -->
                    <li><span>Liên kết email</span><button
                            @click="content=32, email='', emailCode='', countDown=0, countshow=false">Liên kết</button>
                    </li>
                    <!-- <li><span>Thay đổi mật khẩu đăng nhập</span><button
                            @click="content=33, oldPassword='', newPassword='', confirmPassword=''">Sửa đổi</button>
                    </li> -->
                    <li><span>tài khoản zalo</span><button @click="content=34">Sửa đổi</button>
                    </li>
                    <li><span>tài khoản Tên đăng nhập</span><button @click="content=35">Sửa đổi</button>
                    </li>
                </ul>
            </div>

        </div>

        <div v-if="content==31">

            <div class="return" @click="content=2"></div>
            <div class="form">
                <div class="header">
                    Liên kết số điện thoại
                </div>
                <div class="body">
                    <div class="form-group">
                        <vue-tel-input v-bind="bindProps" v-on:country-changed="countryChanged" v-model="phone"
                            autocomplete="off">
                        </vue-tel-input>
                        <!-- <input type="text" v-model="phone"> -->
                        <!-- <div class="placeholder" v-if="phone==''">Enter your Phone Number</div> -->
                        <div class="verify" @click="verifysms()">Xác minh</div>
                        <div class="vcounter" v-show="countshow2">{{ countDown2 }} s</div>
                    </div>
                    <div class="form-group">
                        <input type="text" v-model="phoneCode">
                        <div class="placeholder" v-if="phoneCode==''">Nhập mã xác minh</div>
                    </div>
                    <button @click="verifysmscode(phoneCode)">Liên kết</button>
                </div>
            </div>

        </div>
        <div v-if="content==32">

            <div class="return" @click="content=2"></div>
            <div class="form">
                <div class="header">
                    Liên kết email
                </div>
                <div class="body">
                    <div class="form-group">
                        <input type="text" v-model="email">
                        <div class="placeholder" v-if="email==''">Nhập địa chỉ mail</div>
                        <div class="verify" @click="verifyemail(email)">Xác minh</div>
                        <div class="vcounter" v-show="countshow">{{ countDown }} s</div>
                    </div>
                    <div class="form-group">
                        <input type="text" v-model="emailCode">
                        <div class="placeholder" v-if="emailCode==''">Nhập mã xác minh</div>
                    </div>
                    <button @click="verifyemailcode(emailCode)">Liên kết</button>
                </div>
            </div>

        </div>

        <div v-if="content==33">

            <div class="return" @click="content=2"></div>
            <div class="form">
                <div class="header">
                    Thay đổi mật khẩu đăng nhập
                </div>
                <div class="body">
                    <div class="form-group">
                        <input :type="oldPasswordType" v-model="oldPassword">
                        <div class="placeholder" v-if="oldPassword==''">Mật khẩu cũ</div>
                        <div class="icon eye" @click="oldPasswordTypeChange()"
                            v-bind:class="[{open : oldPasswordType=='text'}]"></div>
                    </div>
                    <div class="form-group">
                        <input :type="oldPasswordType" v-model="newPassword">
                        <div class="placeholder" v-if="newPassword==''">Mật khẩu mới</div>
                        <div class="icon eye" @click="oldPasswordTypeChange()"
                            v-bind:class="{open : oldPasswordType=='text'}"></div>
                    </div>
                    <div class="form-group">
                        <input :type="oldPasswordType" v-model="confirmPassword">
                        <div class="placeholder" v-if="confirmPassword==''">Mật khẩu mới</div>
                        <div class="icon eye" @click="oldPasswordTypeChange()"
                            v-bind:class="{open : oldPasswordType=='text'}"></div>
                    </div>
                    <button @click="changePassword(newPassword,oldPassword,confirmPassword)">Sửa đổi</button>
                </div>
            </div>

        </div>

        <div v-if="content==34">

            <div class="return" @click="content=2"></div>
            <div class="form zalo">
                <div class="header">
                    tài khoản zalo
                </div>
                <div class="body">
                    <div class="form-group">
                        <input type="text" v-model="zalo">
                        <div class="placeholder" v-if="zalo==''">Vui lòng nhập zalo</div>
                    </div>
                    <button @click="changeZalo()">Sửa đổi</button>
                </div>
            </div>

        </div>

        <div v-if="content==35">
            <div class="return" @click="content=2"></div>
            <div class="form username">
                <div class="header" >
                    tài khoản Tên đăng nhập<p style='color:red'>(Chú ý đến các quy tắc)</p>
                </div>
                <div class="body">
                    <div class="form-group">
                        <input type="text" v-model="username">
                        <div class="placeholder" v-if="username==''">Vui lòng nhập Tên đăng nhập</div>
                    </div>
                    <button @click="changeUsername()">Sửa đổi</button>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
    const axios = require('axios')
    const config = require('./../config/index.js')
    import Spinner from './../components/Spinner.vue'
    import LogOutSpinner from './../components/LogOutSpinner.vue'
    import {
        VueTelInput
    } from 'vue-tel-input'
    export default {
        components: {
            VueTelInput,
            Spinner,
            LogOutSpinner
        },
        data() {
            return {
                oldPasswordType: 'password',
                newPasswordType: 'password',
                confirmPasswordType: 'password',
                phoneCode: '',
                emailCode: '',
                phone: '',
                countryCode: null,
                bindProps: {
                    validCharactersOnly: true,
                    placeholder: 'Nhập số điện thoại'
                },
                email: '',
                oldPassword: '',
                newPassword: '',
                confirmPassword: '',
                selectedImg: 0,
                changeAvatarPopUp: false,
                logoutPopUp: false,
                data: {
                    user_id: this.$store.state.user_id,
                    joinday: this.$store.state.joinday,
                    id: this.$store.state.id,
                    avatar: this.$store.state.avatar,
                    mobilestore: this.$store.state.mobilestore,
                    emailstore: this.$store.state.emailstore,
                    zalostore: this.$store.state.zalostore
                },
                content: 1,
                isSpinner: false,
                spinMessage: '',
                failResponse: false,
                isSpinner2: false,
                spinMessage2: '',
                failResponse2: false,
                countshow: false,
                countDown: 0,
                countshow2: false,
                countDown2: 0,
                mobiledisplay: '',
                emaildisplay: '',
                zalodisplay: '',
                showmobile: true,
                showemail: true,
                showzalo: true,
                zalo: '',
                username: ''
            }
        },
        beforeRouteEnter(to, from, next) {
            const getparams = to.query.bind
            next((vm) => {
                if (from.name == 'BankCardManagement') {
                    console.log(getparams)
                    if (getparams !== null) {
                        if (getparams === 'phone') {
                            vm.phone = ''
                            vm.phoneCode = ''
                            vm.countDown2 = 0
                            vm.countshow2 = false
                            vm.content = 31

                        } else if (getparams === 'email') {
                            vm.email = ''
                            vm.emailCode = ''
                            vm.countDown = 0
                            vm.countshow = false
                            vm.content = 32
                        } else {
                            vm.content = 1
                        }
                    } else {
                        vm.content = 1
                    }
                }
            });
        },
        mounted() {
            this.$nextTick(function() {
              this.getInfo()
            })
            if (this.$store.state.user_id == null) {
                this.getInfo()
            } else {
                var self = this
                if (self.data.mobilestore === null || self.data.mobilestore === '' || self.data.mobilestore === undefined) {
                    self.showmobile = false
                } else {
                    self.showmobile = true
                }
                if (self.data.emailstore === null || self.data.emailstore === '' || self.data.emailstore === undefined) {
                    self.showemail = false
                } else {
                    self.showemail = true
                }
                if (self.data.zalostore === null || self.data.zalostore === '' || self.data.zalostore === undefined) {
                    self.showzalo = false
                } else {
                    self.showzalo = true
                }
                self.mobiledisplay = self.data.mobilestore
                self.zalodisplay = self.data.zalostore
                self.emaildisplay = self.data.emailstore


            }
        },
        methods: {
            changeZalo() {

                var self = this
                self.isSpinner = true
                var base =
                    config.accountAuth.baseApi + '?vendor=' + config.accountAuth.vendor + '&cmd=' + config.accountAuth
                    .editZaloCMD + '&zalo=' + this.zalo

                axios.get(base)
                    .then(function (response) {
                        // handle success

                        self.isSpinner = true
                        self.failResponse = true
                        self.spinMessage = response.data.errorMessage

                        setTimeout(() => {
                            self.failResponse = false
                            self.isSpinner = false
                            self.spinMessage = ""

                            if (!response.data.errorCode) {
                                self.content = 1
                                self.getInfo()
                            }

                        }, 2000);



                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error);
                    });
            },
            changeUsername() {

                var self = this
                self.isSpinner = true
                var base =
                    config.accountAuth.baseApi + '?vendor=' + config.accountAuth.vendor + '&cmd=' + config.accountAuth
                    .editUsernameCMD + '&username=' + this.username

                axios.get(base)
                    .then(function (response) {
                        // handle success

                        self.isSpinner = true
                        self.failResponse = true
                        self.spinMessage = response.data.errorMessage

                        setTimeout(() => {
                            self.failResponse = false
                            self.isSpinner = false
                            self.spinMessage = ""

                            if (!response.data.errorCode) {
                                self.content = 1
                                self.getInfo()
                            }

                        }, 2000);

                sessionStorage.removeItem('vuex')
                sessionStorage.removeItem('stayTime')
                localStorage.clear()
                setTimeout(() => {
                    window.location.href = 'https://uhappy.bestahk.com/'
                }, 1000);

                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error);
                    });
            },
            countryChanged(country) {
                this.countryCode = country.dialCode
            },
            countDownTimer() {
                this.countshow = true
                if (this.countDown > 0) {
                    setTimeout(() => {
                        this.countDown -= 1
                        this.countDownTimer()
                    }, 1000)
                } else {
                    this.countshow = false
                }
            },
            countDownTimer2() {
                this.countshow2 = true
                if (this.countDown2 > 0) {
                    setTimeout(() => {
                        this.countDown2 -= 1
                        this.countDownTimer2()
                    }, 1000)
                } else {
                    this.countshow2 = false
                }
            },
            oldPasswordTypeChange() {
                if (this.oldPasswordType == 'text') {
                    this.oldPasswordType = 'password'
                } else {
                    this.oldPasswordType = 'text'
                }
            },
            newPasswordTypeChange() {
                if (this.newPasswordType == 'text') {
                    this.newPasswordType = 'password'
                } else {
                    this.newPasswordType = 'text'
                }
            },
            confirmPasswordTypeChange() {
                if (this.confirmPasswordType == 'text') {
                    this.confirmPasswordType = 'password'
                } else {
                    this.confirmPasswordType = 'text'
                }
            },
            changePassword(newpass, oldpass, confirmpass) {
                var self = this
                self.isSpinner = true
                newpass = newpass.trim()
                oldpass = oldpass.trim()
                confirmpass = confirmpass.trim()
                if (newpass === '' || newpass === null || oldpass === '' || oldpass === null || confirmpass === '' ||
                    confirmpass === null) {
                    self.isSpinner = true
                    self.failResponse = true
                    self.spinMessage = 'Kiểm tra đầu vào'
                    setTimeout(() => {
                        self.failResponse = false
                        self.isSpinner = false
                        self.spinMessage = ""
                    }, 2000);
                    return
                }
                if (newpass !== confirmpass) {
                    self.isSpinner = true
                    self.failResponse = true
                    self.spinMessage = 'Xác nhận mật khẩu không khớp'
                    setTimeout(() => {
                        self.failResponse = false
                        self.isSpinner = false
                        self.spinMessage = ""
                    }, 2000);
                    return
                }
                var base =
                    config.accountAuth.baseApi + '?vendor=' + config.accountAuth.vendor + '&cmd=' + config.accountAuth
                    .changepasswordCMD + '&new_password=' + newpass + '&password=' + oldpass

                axios.get(base)
                    .then(function (response) {
                        // handle success

                        if (response.data.errorCode) {
                            self.isSpinner = true
                            self.failResponse = true
                            self.spinMessage = response.data.errorMessage

                            setTimeout(() => {
                                self.failResponse = false
                                self.isSpinner = false
                                self.spinMessage = ""

                            }, 2000);
                        } else {
                            self.isSpinner = true
                            self.failResponse = true
                            self.spinMessage = response.data.errorMessage

                            setTimeout(() => {
                                self.failResponse = false
                                self.isSpinner = false
                                self.spinMessage = ""
                                self.oldPassword = ""
                                self.newPassword = ""
                                self.confirmPassword = ""
                                self.content = 1
                            }, 2000);
                        }

                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error);
                    });
            },
            changeAvatar(index) {
                var self = this
                self.isSpinner = true
                var base =
                    config.baseApi + '?merchant=' + config.merchant + '&serial=' + config.changeAvatarSerial +
                    '&sign=' +
                    config.sign(config.merchant, config.changeAvatarSerial, config.md5Key) + '&param='

                var param = config.encryptAES('gender=' + index, config.aes)
                axios.get(base + param)
                    .then(function (response) {
                        // handle success
                        if (response.data.success) {
                            self.isSpinner = false
                            self.changeAvatarPopUp = false
                            self.data.avatar = response.data.data.gender
                            self.$store.dispatch('saveAvatar', self.data.avatar)
                        } else {
                            self.failResponse = true
                            self.spinMessage = response.data.message;

                            setTimeout(() => {
                                self.isSpinner = false
                                self.failResponse = false
                                self.spinMessage = "";
                            }, 2000);
                        }


                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error);
                    });

            },
            logout() {
                var self = this
                self.isSpinner = true
                sessionStorage.removeItem('vuex')
                sessionStorage.removeItem('stayTime')
                localStorage.clear()
                setTimeout(() => {
                    window.location.href = 'https://uhappy.bestahk.com/'
                }, 1000);

                // var self = this
                // self.isSpinner = true
                // var base =
                //     config.accountAuth.baseApi + '?vendor=' + config.accountAuth.vendor + '&cmd=' + config.accountAuth
                //     .logoutCMD

                // axios.get(base)
                //     .then(function (response) {
                //         // handle success

                //         if (response.data.errorCode) {
                //             self.isSpinner = true
                //             self.failResponse = true
                //             self.spinMessage = response.data.errorMessage

                //             setTimeout(() => {
                //                 self.failResponse = false
                //                 self.isSpinner = false
                //                 self.spinMessage = ""
                //             }, 2000);
                //         } else {
                //             sessionStorage.removeItem('vuex')
                //             sessionStorage.removeItem('stayTime')
                //             localStorage.clear()

                //             self.isSpinner = false
                //             // location.reload()
                //             location.reload(true)
                //         }

                //     })
                //     .catch(function (error) {
                //         // handle error
                //         console.log(error);
                //     });
            },
            verifyemail(emailadd) {
                var self = this
                self.isSpinner = true
                var base =
                    config.accountAuth.baseApi + '?vendor=' + config.accountAuth.vendor + '&cmd=' + config.accountAuth
                    .verifyemailCMD + '&email=' + emailadd

                axios.get(base)
                    .then(function (response) {
                        // handle success

                        if (response.data.errorCode) {
                            self.isSpinner = true
                            self.failResponse = true
                            self.spinMessage = response.data.errorMessage

                            setTimeout(() => {
                                self.failResponse = false
                                self.isSpinner = false
                                self.spinMessage = ""
                            }, 2000);

                        } else {
                            self.isSpinner = true
                            self.failResponse = true
                            self.spinMessage = response.data.errorMessage

                            setTimeout(() => {
                                self.failResponse = false
                                self.isSpinner = false
                                self.spinMessage = ""
                            }, 2000);
                            self.countDown = 60
                            self.countDownTimer()
                        }

                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error);
                    });
            },
            verifyemailcode(emailcode) {
                var self = this
                self.isSpinner = true
                var base =
                    config.accountAuth.baseApi + '?vendor=' + config.accountAuth.vendor + '&cmd=' + config.accountAuth
                    .verifyemailcodeCMD + '&code=' + emailcode

                axios.get(base)
                    .then(function (response) {
                        // handle success

                        if (response.data.errorCode) {
                            self.isSpinner = true
                            self.failResponse = true
                            self.spinMessage = response.data.errorMessage

                            setTimeout(() => {
                                self.failResponse = false
                                self.isSpinner = false
                                self.spinMessage = ""
                                self.emailCode = ""
                            }, 2000);
                        } else {
                            self.isSpinner = true
                            self.failResponse = true
                            self.spinMessage = response.data.errorMessage

                            setTimeout(() => {
                                self.failResponse = false
                                self.isSpinner = false
                                self.spinMessage = ""
                                self.email = ""
                                self.emailCode = ""
                                self.getInfo()
                                self.content = 1
                            }, 2000);
                        }

                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error);
                    });
            },
            verifysms() {
                var self = this
                self.isSpinner = true
                var base =
                    config.accountAuth.baseApi + '?vendor=' + config.accountAuth.vendor + '&cmd=' + config.accountAuth
                    .verifysmsCMD + '&mobile=' + self.countryCode + self.phone.replace(/\s+/g, '')

                axios.get(base)
                    .then(function (response) {
                        // handle success

                        if (response.data.errorCode) {
                            self.isSpinner = true
                            self.failResponse = true
                            self.spinMessage = response.data.errorMessage

                            setTimeout(() => {
                                self.failResponse = false
                                self.isSpinner = false
                                self.spinMessage = ""
                            }, 2000);
                        } else {
                            self.isSpinner = true
                            self.failResponse = true
                            self.spinMessage = response.data.errorMessage

                            setTimeout(() => {
                                self.failResponse = false
                                self.isSpinner = false
                                self.spinMessage = ""
                            }, 2000);
                            self.countDown = 60
                            self.countDownTimer()
                        }

                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error);
                    });
            },
            verifysmscode(smscode) {
                var self = this
                self.isSpinner = true
                var base =
                    config.accountAuth.baseApi + '?vendor=' + config.accountAuth.vendor + '&cmd=' + config.accountAuth
                    .verifysmscodeCMD + '&code=' + smscode

                axios.get(base)
                    .then(function (response) {
                        // handle success

                        if (response.data.errorCode) {
                            self.isSpinner = true
                            self.failResponse = true
                            self.spinMessage = response.data.errorMessage

                            setTimeout(() => {
                                self.failResponse = false
                                self.isSpinner = false
                                self.spinMessage = ""
                                self.phoneCode = ""
                            }, 2000);
                        } else {
                            self.isSpinner = true
                            self.failResponse = true
                            self.spinMessage = response.data.errorMessage

                            setTimeout(() => {
                                self.failResponse = false
                                self.isSpinner = false
                                self.spinMessage = ""
                                self.phone = ""
                                self.phoneCode = ""
                                self.getInfo()
                                self.content = 1
                            }, 2000);
                        }

                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error);
                    });
            },
            getImg(pic) {
                return require('./../assets/img/avatar/' + pic + '.jpg')
            },
            getInfo() {
                var self = this
                self.isSpinner2 = true

                var base =
                    config.accountAuth.baseApi + '?vendor=' + config.accountAuth.vendor + '&cmd=' + config.accountAuth
                    .personalInfoCMD

                axios.get(base)
                    .then(function (response) {
                        // handle success

                        if (response.data.errorCode) {

                            if (response.data.errorCode == 12) {

                                self.isSpinner2 = true
                                self.failResponse2 = true
                                self.spinMessage2 = response.data.errorMessage

                            }

                        } else {
                            self.isSpinner2 = false
                            self.data = response.data.result
                            self.$store.dispatch('saveInfo', {
                                user_id: self.data.user_id,
                                joinday: self.data.joinday,
                                id: self.data.id,
                                mobilestore: self.data.mobile,
                                emailstore: self.data.email,
                                zalostore: self.data.zalo
                            })
                            self.$store.dispatch('saveAvatar', self.data.avatar)

                            if (self.data.mobile === null || self.data.mobile === '') {
                                self.showmobile = false
                            } else {
                                self.showmobile = true
                            }
                            if (self.data.email === null || self.data.email === '') {
                                self.showemail = false
                            } else {
                                self.showemail = true
                            }
                            if (self.data.zalo === null || self.data.zalo === '') {
                                self.showzalo = false
                            } else {
                                self.showzalo = true
                            }
                            self.mobiledisplay = self.data.mobile
                            self.zalodisplay = self.data.zalo
                            self.emaildisplay = self.data.email
                        }

                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error);
                    });
            }
        }
    }
</script>
<style>
    .personal-information .vti__input {
        background: transparent;
    }

    .personal-information .vti__input::placeholder {
        color: #000;
    }
</style>
<style lang="less" scoped>
    .personal-information {
        .avatar {
            padding: 10px;
            display: flex;

            img {
                border-radius: 100%;
                width: 17%;
                height: 17%;
                float: left;
            }

            .desc {
                padding-left: 10px;
                color: #333;
                height: 100%;
                float: left;

                h4 {
                    margin-top: 5px;
                    font-size: 27px;
                }

                .infocon {
                    display: table;

                    p {
                        // margin-top: 10px;
                        font-size: 17px;
                        display: table-row;

                        span {
                            display: table-cell;
                            line-height: 24px;

                            &.infores {
                                padding-left: 8px;
                            }

                            .infobind {
                                border: none;
                                height: 25px;
                                color: #FFF;
                                width: auto;
                                padding: 0px 10px;
                                color: #FFF;
                                background-color: #00a500;
                                margin: 2px 0px;
                            }
                        }

                    }
                }

            }
        }

        .row {
            display: flex;
        }

        .form {
            position: absolute;
            height: 90%;
            width: 70%;
            top: 50%;
            left: 0;
            right: 0;
            margin: auto;
            transform: translateY(-50%);

            .header {
                text-align: center;
                height: 15%;
                font-size: 18px;
                line-height: 100%;
                width: 100%;
                position: relative;
                background-color: #ddd;
                display: flex;
                justify-content: center;
                align-content: center;
                flex-direction: column;
                color: #111;
            }

            &.zalo {

                transform: translateY(-30%);

                .body {
                    height: 50%;

                    .form-group,
                    button {
                        height: 37%;
                    }
                }
            }

            .body {
                width: 100%;
                display: inline-block;
                height: 85%;
                background-color: #FFF;
                text-align: center;

                .form-group {
                    display: inline-block;
                    height: 18%;
                    width: 75%;
                    background-color: #fff;
                    border: none;
                    position: relative;
                    margin-bottom: 1%;
                    border: 2px solid #ddd;

                    &:first-child {
                        margin-top: 3%;
                    }

                    .vue-tel-input {
                        border: none;
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        left: 0px;
                        z-index: 3;
                        outline: none;
                        text-align: left;
                        background-color: transparent;
                    }

                    .verify {
                        display: flex;
                        justify-content: center;
                        align-content: center;
                        flex-direction: column;
                        background-color:#52bf52;
                        color: #FFF;
                        position: absolute;
                        right: 0;
                        height: 100%;
                        width: 70px;
                        z-index: 3;
                    }

                    .vcounter {
                        display: flex;
                        justify-content: center;
                        align-content: center;
                        flex-direction: column;
                        background-color:#52bf52;
                        color: #FFF;
                        position: absolute;
                        right: 0;
                        height: 100%;
                        width: 70px;
                        z-index: 4;
                    }

                    input {
                        border: none;
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        left: 10px;
                        z-index: 1;
                        outline: none;
                        text-align: left;
                        background-color: transparent;
                    }

                    .placeholder {
                        display: flex;
                        justify-content: center;
                        align-content: center;
                        flex-direction: column;
                        text-align: left;
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        left: 10px;
                        z-index: 0;
                        color: #969696;
                    }

                    .icon {
                        background-position: center;
                        width: 15px;
                        top: 50%;
                        transform: translateY(-50%);
                        margin: auto;
                        right: 0;
                        left: 0;
                        position: relative;
                        height: 15px;
                        background-repeat: no-repeat;
                        background-size: contain;

                        &.remove {
                            background-image: url('./../assets/icons/entry_clear_icon.png');
                            right: 15px;
                            z-index: 1;
                            position: absolute;
                            left: initial;
                        }

                        &.eye {
                            background-image: url('./../assets/icons/eye-close.svg');
                            position: absolute;
                            right: -25px;
                            left: initial;
                            background-color: rgba(151, 141, 141, 0.3);
                            border-radius: 100px;
                            z-index: 1;
                            height: 20px;
                            width: 20px;

                            &.open {
                                background-image: url('./../assets/icons/eye-open.svg');
                            }
                        }
                    }

                }

                button {
                    width: 75%;
                    height: 18%;
                    border: none;
                    background-color: #52bf52;
                    color: #FFF;
                }
            }
        }

    }

    .others {
        position: absolute;
        height: 30px;
        width: 150px;
        top: 10px;
        right: 10px;

        ul {
            li {
                padding: 0 10px;
                list-style-type: none;
                float: left;

                div {
                    height: 30px;
                    width: 30px;
                    background-repeat: no-repeat;
                    background-size: 100% 100%;

                    &.security {
                        background-image: url('./../assets/img/personal-center/security-icon-gold.png');
                    }

                    &.logout {
                        background-image: url('./../assets/img/personal-center/logout.png');
                    }

                    &.customer {
                        background-image: url('./../assets/icons/customer-service.png');
                    }
                }
            }
        }
    }

    .return {

        background-image: url('./../assets/img/personal-center/return-icon.png');
        background-repeat: no-repeat;
        background-size: contain;
        height: 40px;
        width: 60px;
        margin-left: 10px;
        margin-top: 10px;
    }

    .logout-popup {
        height: 100vh;
        width: 100%;
        position: fixed;
        background-color: rgba(62, 62, 62, .85);
        z-index: 1;
        top: 0;
        left: 0;

        .content {
            border-radius: 18px;
            text-align: center;
            color: #000;
            background-color: #ccc;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
            width: 45%;
            top: 50%;
            transform: translateY(-50%);

            .body {
                font-size: 20px;
                padding: 20px 10px;
            }

            .footer {

                ul {
                    li {
                        width: 50%;
                        background-color: rgba(255, 255, 255, 0.56);
                        height: 40px;
                        float: left;
                        list-style-type: none;
                        line-height: 40px;
                        border-radius: 15px;
                    }
                }
            }
        }
    }

    .change-avatar {
        position: fixed;
        height: 100vh;
        width: 100%;
        left: 0;
        top: 0;

        .dim {
            position: fixed;
            height: 100vh;
            width: 100%;
        }

        .content {
            border-radius: 18px;
            text-align: center;
            color: #000;
            // background-image: url('./../assets/img/personal-center/content.png');
            // background-repeat: no-repeat;
            // background-size: 100% 100%;
            position: absolute;
            bottom: 4%;
            height: 60%;
            background-color: #fff;
            width: 66%;
            left: 0;
            right: 0;
            margin-left: 32%;
            border-radius: 0;

            .choices {
                height: 80%;
                width: 100%;
                display: block;
                overflow-y: scroll;
                animation: avatarAnim .5s ease;

                ul {
                    margin: 5px 10px;

                    li {
                        padding: 3px 0;
                        list-style-type: none;
                        text-align: center;
                        float: left;
                        width: 25%;

                        .cont {
                            display: inline-block;
                            position: relative;

                            img {
                                width: 83%;
                                border: 3px solid transparent;
                                border-radius: 100%;
                            }

                            .check {
                                position: absolute;
                                bottom: 3px;
                                width: 20px;
                                height: 20px;
                                z-index: 1;
                                right: 5px;
                                display: none;
                                background-repeat: no-repeat;
                                background-size: contain;
                                background-image: url('../assets/img/games/check-circle.png');
                            }

                            &.selected {
                                img {
                                    border-color: #d2b79c;
                                }

                                .check {
                                    display: inline-block;
                                }
                            }
                        }
                    }
                }
            }

            .footer {
                height: 20%;
                background-color: #16cc00;
                color: #FFF;
                position: relative;

                span {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 0;
                    right: 0;
                    margin: auto;
                }
            }
        }
    }

    @keyframes avatarAnim {
        0% {
            bottom: -180px;
        }

        100% {
            bottom: 0;
        }
    }

    .selection {
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        width: 90%;
        height: 50%;
        top: 50%;
        transform: translateY(-50%);

        ul {
            height: 100%;

            li {
                line-height: 30%;
                margin-bottom: 1.5%;
                height: 30%;
                list-style-type: none;
                background-color: #ddd;
                position: relative;
                border: 1px solid #a9a9a9;

                &:last-child {
                    margin-bottom: 0;
                }

                span {
                    color: #333;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 10px;
                }

                button {
                    border: none;
                    height: 60%;
                    color: #FFF;
                    background-color: #52bf52;

                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    right: 10px;
                    width: 60px;
                }
            }
        }
    }

    @media (orientation: portrait) {
        // .logout-popup {
        //     width: 100vh;
        //     height: 100vw;
        // }

        // .change-avatar {
        //     width: 100vh;
        //     height: 100vw;
        //     right: 0;
        //     left: 0;
        //     margin: auto;
        // }
    }
    @media only screen and (max-width: 768px) and (orientation: portrait) {
        .personal-information {
            .form {
                position: relative;
                height: auto;
                width: 96%;
                top: 0px;
                left: 0;
                right: 0;
                margin: auto;
                transform: initial;
                padding: 20px 0px;
                .header {
                    height: 50px;
                }
                .body {
                    height: auto;
                    padding: 20px 0px;
                    .form-group {
                        height: 40px;
                        width: 96%;
                        margin-bottom: 10px;
                        input {
                            height: 40px;
                        }
                    }
                    button {
                        height: 40px;
                        margin-top: 15px;
                        font-size: 14px;
                    }
                }
                &.zalo {
                    transform: initial;
                    .body {
                        .form-group {
                            height: 40px;
                        }
                        button {
                            height: 40px;
                        }
                    }
                }
            }
            .avatar {
                .desc {
                    width: 83%;
                    .infocon {
                        p {
                            font-size: 14px;
                        }
                    }
                }
            }
        }
        .others {
            width: auto;
        }
        .selection {
            position: relative;
            left: 0;
            right: 0;
            margin: auto;
            width: 96%;
            height: auto;
            top: 0px;
            transform: initial;
            padding: 20px 0px;
            ul {
                li {
                    line-height: 30px;
                    margin-bottom: 15px;
                    height: 50px;
                }
            }
        }
        .logout-popup {
            .content {
                width: 94%;
                .footer {
                    ul {
                        li {
                            width: 48%;
                            font-size: 12px;
                            margin: 0px 1%;
                        }
                    }
                }
            }
        }
        .change-avatar {
            .content {
                width: 100%;
                bottom: 0px;
                height: 200px;
                margin-left: 0px;
            }
        }
    }
    
</style>
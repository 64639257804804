<template>
    <div id="app">
        <router-view />
    </div>
</template>
<script>

    export default {
        data() {
            return {
                menu: false
            }
        },
        methods: {
            openMenu() {
                this.menu = !this.menu
            }
        }
    }
</script>

<style lang="less">

    body,
    html {
        padding: 0;
        margin: 0;
    }

    * {
        margin: 0;
        padding: 0;
    }
        .nav {
        position: fixed;
        top: 0;
        z-index: 400;
        height: 44px;
        width: 100%;
        user-select: none;
        text-align: center;
        line-height: 44px;
        background-color: #FFF;

        .profile {
            border-radius: 100%;
            width: 45px;
            float: left;
            margin-top: 3px;
            margin-left: 15px;
        }

        .logo {
            width: 160px;
            height: 100%;
            position: absolute;
            left: 15px;
            background: url('./assets/img/logo.png') no-repeat left center;
            background-size: contain;
        }

        .user-id {
            float: left;
            width: 30%;

            .id {
                padding-left: 11px;
                line-height: 100%;
                margin-top: 18px;
                text-align: left;
                font-size: 12px;
            }
        }



        .message {
            position: absolute;
            right: 43px;
            top: 12px;
            width: 20px;
            background-image: url('./assets/img/games/message.png');
            background-size: 100% 100%;
            height: 20px;

            .dot {
                position: absolute;
                top: -2px;
                /* background-color: #fb9393; */
                background-color: #db6372;
                width: 6px;
                height: 6px;
                border-radius: 100px;
                right: -4px;
            }

            span {
                font-size: 12px;
                position: absolute;
                color: #d2b79c;
                right: -45px;
                width: 50px;
                top: -10px;
            }
        }

        .cog {
            top: 0px;
            height: 100%;
            width: 20px;
            position: absolute;
            right: 60px;
            background: url('./assets/img/games/cog.png') no-repeat left center;
            background-size: contain;
        }

        .user {
            height: 100%;
            width: 25px;
            position: absolute;
            right: 15px;
            background: url('./assets/icons/man-user.svg') no-repeat left center;
            background-size: contain;
        }

        .back {
            height: 18px;
            top: 14px;
            width: 11px;
            position: absolute;
            left: 14px;
            background: url('./assets/img/games/back.png') no-repeat left center;
            background-size: contain;
        }

        .questions {
            top: 0;
            height: 100%;
            width: 20px;
            position: absolute;
            right: 15px;
            background: url('./assets/img/games/question.png') no-repeat left center;
            background-size: contain;
        }

        .title {
            margin: 0 auto;
            max-width: 60%;
            font-size: 16px;
            font-weight: 500;
            // font-family: PingFangSC-Regular;
        }

        .plus {
            height: 100%;
            width: 20px;
            top: 0;
            position: absolute;
            right: 14px;
            background: url('./assets/img/games/plus.png') no-repeat left center;
            background-size: contain;
        }

    }

    .loader {
        top: 0;
        position: fixed;
        width: 100%;
        height: 100%;
        z-index: 3000;
        background-color: rgba(0, 0, 0, 0.7);

        img {
            width: 50px;
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    @media all and (orientation: portrait) {
    // body {
    //     -webkit-transform: rotate(90deg); 
    //     -moz-transform: rotate(90deg); 
    //     -ms-transform: rotate(90deg); 
    //     -o-transform: rotate(90deg); 
    //     transform: rotate(90deg); 
    //     #app{    
    //         height: 100vw;
    //         width: 100vh;
    //     }
    // }
}
</style>
import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home1 from '../views/Home1.vue'
// import Home2 from '../views/Home2.vue'
import Login from '../views/Login.vue'
import BillingRecord from '../views/BillingRecord.vue'
import GameRecord from '../views/GameRecord.vue'
import WithdrawalRecord from '../views/WithdrawalRecord.vue'
import Withdraw from '../views/Withdraw.vue'
import PersonalCenter from '../views/PersonalCenter.vue'
import SecurityCenter from '../views/SecurityCenter.vue'
import BankCardManagement from '../views/BankCardManagement.vue'
import PersonalInformation from '../views/PersonalInformation.vue'
import Register from '../views/Register.vue'
import ForgotPassword from '../views/ForgotPassword.vue'
import ForgotPasswordEmail from '../views/ForgotPasswordEmail.vue'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import vueSmoothScroll from 'vue-smooth-scroll'
import SlideVerify from 'vue-monoplasty-slide-verify';
import store from '../store/index.js'
// require styles

Vue.use(VueAwesomeSwiper, /* { default global options } */ )
Vue.use(VueRouter)
Vue.use(vueSmoothScroll)
Vue.use(SlideVerify)

const routes = [{
        path: '/',
        name: 'Login',
        component: Login,
        // meta: {
        //     requiresAuth: true
        // }
    },
    {
        path: '/register',
        name: 'Register',
        component: Register
    },
    // {
    //     path: '/game',
    //     name: 'Home2',
    //     component: Home2,
    //     meta: {
    //         requiresAuth: true
    //     }
    // },
    {
        path: '/forgot-password',
        name: 'ForgotPassword',
        component: ForgotPassword
    },
    {
        path: '/forgot-password-email',
        name: 'ForgotPasswordEmail',
        component: ForgotPasswordEmail
    },
    {
        path: '/personal-center',
        name: 'PersonalCenter',
        component: PersonalCenter,
        meta: {
            requiresAuth: true
        },
        children: [{
            path: '/personal-center/information',
            name: 'PersonalInformation',
            component: PersonalInformation
        }, {
            path: '/personal-center/security',
            name: 'SecurityCenter',
            component: SecurityCenter
        }, {
            path: '/personal-center/bank-card',
            name: 'BankCardManagement',
            component: BankCardManagement
        }, {
            path: '/personal-center/withdraw',
            name: 'Withdraw',
            component: Withdraw
        }, {
            path: '/personal-center/withdrawal-record',
            name: 'WithdrawalRecord',
            component: WithdrawalRecord
        }, {
            path: '/personal-center/billing-record',
            name: 'BillingRecord',
            component: BillingRecord
        }, {
            path: '/personal-center/game-record',
            name: 'GameRecord',
            component: GameRecord
        }]
    }
]

const router = new VueRouter({
    routes,
    scrollBehavior() {
        return { x: 0, y: 0 }
    }
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        // this route requires auth, check if logged in
        // if not, redirect to login page.
        if (!store.state.isLoggedIn) {
            next({ name: 'Login' })
        } else {
            next() // go to wherever I'm going
        }
    } else {
        next() // does not require auth, make sure to always call next()!
    }
    document.title = to.meta.title || 'Sảnh trò chơi'
next()
})

export default router
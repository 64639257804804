<template>
    <div class='withdraw'>
        <Spinner v-if="isSpinner" :msg='spinMessage' :status="failResponse" />
        <LogOutSpinner v-if="isSpinner2" :msg='spinMessage2' :status="failResponse2" />
        <div class="counter-cont">
            <ul>
                <li>
                    <div class="title">
                        <div></div>
                        <span></span>
                    </div>
                    <div class="price">
                        <span>số dư: {{ centralWallet }}</span>
                        <span>freeze:{{ frozen }}</span>
                    </div>
                    <div class="refresh" @click="refresh()"></div>
                </li>
                <!--                <li>-->
                <!--                    <div class="title">-->
                <!--                        <div></div>-->
                <!--                        <span>中心钱包</span>-->
                <!--                    </div>-->
                <!--                    <div class="price">-->
                <!--                        <span>{{ lockWallet }}</span>-->
                <!--                    </div>-->
                <!--                </li>-->
            </ul>
        </div>
        <div class="content">
            <div class="title">
                Thẻ ngân hàng
            </div>
            <ul>
                <li v-for="(card, index) in cards" v-bind:key="index">
                    <div class="cont">
                        <img :src="getImg(card.icon)" alt="">
                        <div class="bank-name"></div>
                        <div class="card-number">{{ card.card_no }}</div>
                        <div class="check" @click="chooseCard(index)" v-bind:class="{ click: index == selectedIndex }">
                        </div>
                    </div>
                </li>
                <li class="add-bank" v-if="cards.length == 0" @click="addBank()">
                    <div class="cont">
                        <div class="min-cont">
                            <div class="plus"></div>
                            <span>Thêm thẻ ngân hàng</span>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <div class="divider"></div>
        <div class="note">
            Số tiền rút tối thiểu là 50,000 USDT
        </div>
        <div class="new-value">lần đổi này tiêu thụ số chip : <span>{{ newValue }}</span></div>
        <div class="amount">
            <div class="entry">
                <!--                <span>¥</span>-->
                <div class="input-form">
                    <div class="showvalue"><input type="tel" pattern="[0-9]*"
                            v-bind:style="{ width: (amount.length*10+20) + 'px' }" @input="checkInput()"
                            v-model="amount" value="" v-on:keypress="isNumber($event, amount)"></div>
                    <!-- <div class="placeholder" v-if="amount == ''">请输入取款金额（{{ min }}-{{ max }}）</div> -->
                </div>
            </div>
            <!--            <div class="max-amount" data-label="取款页取款最大金额按钮点击次数">最大金额</div>-->
        </div>
        <!-- <div class="gold-border"></div> -->
        <div class="btn-cont">
            <button :disabled="btnDisabled" v-bind:class="{disabled: btnDisabled}" @click="withdraw()">Gửi đi</button>
        </div>
    </div>
</template>

<script>
    const axios = require('axios')
    const config = require('./../config/index.js')
    import Spinner from './../components/Spinner.vue'
    import LogOutSpinner from './../components/LogOutSpinner.vue'
    export default {
        components: {
            Spinner,
            LogOutSpinner
        },
        data() {
            return {
                newValue: 0,
                prevWallet: '0.00',
                firstLoad: 0,
                isSpinner: false,
                spinMessage: '',
                failResponse: false,
                isSpinner2: false,
                spinMessage2: '',
                failResponse2: false,
                centralWallet: '0.00',
                lockWallet: '0.00',
                cards: [],
                selectedIndex: 0,
                newVal: '',
                amount: '',
                cardID: '',
                min: 0,
                max: 0,
                btnDisabled: true,
                frozen: '0.00'
            }
        },
        mounted() {
            this.getWallet()
            this.getBankCard()
            this.firstLoad = 1
            this.getBalFrozen()
            // if (this.$store.state.withdrawCards) {
            //     this.cards = this.$store.state.withdrawCards
            //     this.cardID = this.$store.state.withdrawCardID
            // } else {
            //     this.getBankCard()
            // }

        },
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                if (from.name == 'Mine') {
                    if (vm.firstLoad == 0) {
                        vm.getWallet()
                    }
                    vm.firstLoad = 0
                }
            });
        },
        methods: {
        isNumber(event, amtnumber) {
                if (!/\d/.test(event.key) &&
                    (event.key !== /\./.test(amtnumber))
                )
                    return event.preventDefault();
            },
            getBalFrozen() {

                if (this.$route.query.balance == undefined) {
                    if (this.$store.state.balance == undefined || this.$store.state.balance == null) {
                        this.centralWallet = '0.00'
                    } else {
                        if (this.$store.state.balance == 0) {
                            this.centralWallet = '0.00'
                        } else {
                            this.centralWallet = this.$store.state.balance
                        }
                    }
                } else {
                    if (this.$route.query.balance == '') {
                        this.centralWallet = '0.0'
                    } else {
                        this.centralWallet = this.$route.query.balance
                    }
                    this.$store.dispatch('saveBal', this.centralWallet)
                }


                if (this.$route.query.frozen == undefined) {
                    if (this.$store.state.frozen == undefined || this.$store.state.frozen == null) {
                        this.frozen = '0.00'
                    } else {
                        if (this.$store.state.frozen == 0) {
                            this.frozen = '0.00'
                        } else {
                            this.frozen = this.$store.state.frozen
                        }
                    }
                } else {
                    if (this.$route.query.frozen == '') {
                        this.frozen = '0.00'
                    } else {
                        this.frozen = this.$route.query.frozen
                    }
                    this.$store.dispatch('saveFrozen', this.frozen)
                }

            },
            refresh() {
                var self = this
                self.centralWallet = "0.00"
                self.frozen = "0.00"
                self.getWallet()
            },
            getWallet() {
                var self = this
                self.isSpinner = true
                var base =
                    config.accountAuth.baseApi + '?vendor=' + config.accountAuth.vendor + '&cmd=' + config.accountAuth.personalInfoCMD
                axios.get(base)
                    .then(function (response) {
                        // handle success
                        if (response.data.errorCode === 0) {
                            var bal = response.data.result['balance']
                            var fro = response.data.result['frozen']
                            self.$store.dispatch('saveBal', bal)
                            self.$store.dispatch('saveFrozen', fro)
                            if (response.data.result['balance'] == 0) {
                                self.centralWallet = "0.00"
                            } else {
                                self.centralWallet = bal
                            }
                            if (response.data.result['frozen'] == 0) {
                                self.frozen = "0.00"
                            } else {
                                self.frozen = fro
                            }

                        } else {
                            console.log('error')
                        }
                        self.isSpinner = false
                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error);
                    });
            },
            withdraw() {
                var self = this
                self.isSpinner = true
                var base =
                    config.baseApi + '?merchant=' + config.merchant + '&serial=' + config.submitWithdrawGame +
                    '&sign=' +
                    config.sign(config.merchant, config.submitWithdrawGame, config.md5Key) + '&param='
                // var param = config.encryptAES('card_id=' + self.cardID + '&amount=' + parseInt(self.amount * 1000),
                var param = config.encryptAES('card_id=' + self.cardID + '&amount=' + parseInt(self.amount),
                    config.aes)
                axios.get(base + param)
                    .then(function (response) {
                        // handle success

                        if (response.data.success) {
                            self.isSpinner = false
                            self.$router.push('/personal-center/withdrawal-record')
                        } else {
                            self.isSpinner = true
                            self.failResponse = true
                            self.spinMessage = response.data.message
                            setTimeout(() => {
                                self.failResponse = false
                                self.isSpinner = false
                                self.spinMessage = ""
                            }, 2000);
                        }

                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error);
                    });
            },
            checkInput() {
                if (this.amount != '') {
                    // this.newVal = this.amount * 1000
                    // this.newValue = this.newVal * 0
                    // this.newValue = this.newValue + this.newVal
                    //  this.newValue =  this.newVal
                    // if (this.newVal >= 100000 && this.newVal <= this.centralWallet) {
                    this.newVal = this.amount 
                    this.newValue = this.newVal
                    if (parseInt(this.newVal) >= 50 && parseInt(this.newVal) <= parseInt(this.centralWallet)) {
                        this.btnDisabled = false
                    } else {
                        this.btnDisabled = true
                       
                    }

                } else {
                    this.newValue = 0
                    this.btnDisabled = true
                    
                }
            },
            chooseCard(index) {
                this.selectedIndex = index
                this.cardID = this.cards[this.selectedIndex].card_id
                // console.log(this.cards[this.selectedIndex].card_id)
            },
            addBank() {
                this.$router.push({
                    name: 'BankCardManagement'
                })
            },
            getImg(pic) {
                return require('./../assets/img/vnd_banks/' + pic + '.png')
            },
            getBankCard() {
                var self = this
                self.isSpinner2 = true
                var base =
                    config.baseApi + '?merchant=' + config.merchant + '&serial=' + config.withdrawalSerial + '&sign=' +
                    config.sign(config.merchant, config.withdrawalSerial, config.md5Key) + '&param='

                axios.get(base)
                    .then(function (response) {
                        // handle success

                        if (response.data.success) {
                            self.isSpinner2 = false
                            // switch min/max
                            // self.min = parseInt(response.data.data.min)
                            // self.max = parseInt(response.data.data.max)
                            // self.centralWallet = response.data.data.central_wallet
                            // self.prevWallet = self.centralWallet
                            // self.lockWallet = response.data.data.lock_wallet
                            // self.cards = response.data.data.cards

                            self.cardID = response.data.data.cards[self.selectedIndex].card_id
                            self.cards = response.data.data.cards
                            self.$store.dispatch('saveWithdrawCardID', response.data.data.cards[self.selectedIndex]
                                .card_id)
                            self.$store.dispatch('saveWithdrawCards', response.data.data.cards)

                        } else {
                            console.log(response.data)
                        }


                        if (response.data.errorCode == 21) {

                            self.isSpinner2 = true
                            self.failResponse2 = true
                            self.spinMessage2 = response.data.errorMessage

                        }
                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error);
                    });
            }
        }
    }
</script>
<style lang="less" scoped>
    .withdraw {

        .counter-cont {
            background-color: rgba(148, 148, 148, .2);

            ul {
                line-height: 40px;
                height: 40px;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                text-align: left;

                li {
                    float: left;
                    width: 100%;
                    -webkit-box-sizing: border-box;
                    box-sizing: border-box;
                    position: relative;
                    list-style-type: none;
                    color: white;
                    /*&:first-child::after {*/
                    /*    content: "";*/
                    /*    position: absolute;*/
                    /*    right: 0;*/
                    /*    top: 8px;*/
                    /*    height: 20px;*/
                    /*    width: 1px;*/
                    /*    background: #eee;*/
                    /*}*/

                    .title {
                        height: 17px;
                        line-height: 17px;
                        text-align: center;
                        margin-bottom: 1px;
                        font-weight: 700;
                        display: inline-block;

                        div {
                            width: 23px;
                            height: 20px;
                            display: inline-block;
                            background-repeat: no-repeat;
                            background-size: contain;
                            vertical-align: middle;
                            margin-right: 3px;
                            margin-left: 15px;
                            margin-top: -6px;
                            background-size: 100% 100%;
                            background-image: url('./../assets/img/personal-center/wallet.png');
                        }

                        span {
                            font-size: 12px;
                        }
                    }

                    .price {
                        margin-left: 5px;
                        line-height: 19px;
                        height: 19px;
                        text-align: center;
                        display: inline-block;

                        span {
                            font-size: 17px;
                            font-weight: 500;
                            color: #ff0000;

                            &:nth-child(2) {
                                margin-left: 15px;
                            }
                        }
                    }

                    .refresh {
                        margin-bottom: -2px;
                        margin-left: 21px;
                        display: inline-block;

                        width: 17px;
                        height: 17px;
                        margin-top: 5px;
                        background-repeat: no-repeat;
                        background-size: 100% 100%;
                        background-image: url('./../assets/img/games/refresh2.png')
                    }
                }
            }
        }

        .content {
            padding: 0;

            .title {
                background-color: rgba(148, 148, 148, .3);
                height: 20px;
                line-height: 20px;
                font-size: 12px;
                color: #333;
                position: relative;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                padding-left: 14px;
            }

            ul {
                li {
                    position: relative;
                    height: 35px;
                    line-height: 35px;
                    background-color: rgba(255, 255, 255, .1);
                    padding-left: 14px;

                    .cont {

                        img {
                            width: 15px;
                            height: 15px;
                            border-radius: 100%;
                            padding: 6px;
                            display: inline-block;
                            background-color: white;
                            vertical-align: middle;
                        }

                        .bank-name {
                            height: 20px;
                            display: inline-block;
                            line-height: 20px;
                            font-size: 15px;
                            color: #333;
                            margin: 0 10px 0 15px;
                        }

                        .card-number {
                            height: 20px;
                            display: inline-block;
                            line-height: 20px;
                            font-size: 15px;
                            color: #333;
                        }

                        .check {
                            width: 19px;
                            height: 17px;
                            border: none;
                            border-radius: 50%;
                            position: absolute;
                            top: 12px;
                            left: 176px;
                            background-color: initial;
                            background-repeat: no-repeat;
                            background-size: contain;
                            background-image: url('./../assets/img/personal-center/check-box.png');

                            &.click {
                                background-image: url('./../assets/img/personal-center/checked-box.png');
                            }
                        }
                    }

                    &.add-bank {
                        text-align: center;
                        vertical-align: middle;
                        padding-left: 0;
                        color: #999;

                        .cont {

                            .min-cont {
                                position: relative;

                                span {
                                    display: inline-block;
                                    margin-left: 17px;

                                }

                                .plus {
                                    top: 50%;
                                    transform: translateY(-50%);
                                    position: absolute;
                                    height: 15px;
                                    vertical-align: middle;
                                    width: 15px;
                                    display: inline-block;
                                    background-image: url('./../assets/img/games/plus.png');
                                    background-repeat: no-repeat;
                                    background-size: contain;
                                }
                            }
                        }
                    }
                }
            }
        }
        .divider{
            background-color: rgba(148, 148, 148, .2);
            height: 10px;
            margin-top: 8px;
        }
        .note {
            line-height: 40px;
            text-align: left;
            font-size: 12px;
            color: #333;
            padding: 0 10px;
            background-color: rgba(148, 148, 148, .2);
        }
        .new-value{
            
            line-height: 40px;
            text-align: left;
            font-size: 15px;
            color: #333;
            padding: 0 10px;
            background-color: rgba(148, 148, 148, .2);
            span{
                color: #ff0000;
            }
        }

        .amount {
            height: 20px;
            line-height: 20px;
            background: rgba(255, 255, 255, .1);
            display: flex;
            align-items: center;
            -webkit-box-pack: justify;
            -webkit-justify-content: space-between;
            -ms-flex-pack: justify;
            justify-content: space-between;
            padding: 20px 10px;
            box-sizing: border-box;

            .entry {
                margin-bottom: -25px;
                width: 100%;
                display: flex;
                -webkit-box-align: center;
                -webkit-align-items: center;
                -ms-flex-align: center;
                align-items: center;
                -webkit-box-pack: center;
                -webkit-justify-content: center;
                -ms-flex-pack: center;
                justify-content: center;

                span {
                    font-size: 13px;
                    font-weight: 700;
                    margin-right: 5px;
                    bottom: 1px;
                }

                .input-form {
                    display: inline-block;
                    position: relative;
                    width: 100%;
                    text-align: center;
                    font-size: 20px;

                    input {
                        font-size: 23px;
                        line-height: 25px;
                        text-align: center;
                        color: #FFF;
                        height: 100%;
                        border: none;
                        border-bottom: 1px solid #d2b79c;
                        background: transparent;
                        padding: 0;
                        outline: none;
                    }

                    .showvalue {
                        font-size: 25px;
                        color: #333;
                        height: 100%;
                        input{
                            color: #ff0000;
                        }
                    }

                    .placeholder {
                        white-space: nowrap;
                        position: absolute;
                        height: 26px;
                        line-height: .26px;
                        color: #a5a9b3;
                        font-size: 14px;
                        top: 13px;
                        z-index: -1;
                    }
                }
            }

            .max-amount {
                height: 20px;
                line-height: 20px;
                font-size: 14px;
                color: #a5a9b3;
            }
        }


        .gold-border {
            height: 1px;
            background-color: #b79a7f;
        }

        .btn-cont {
            background-color: rgba(255, 255, 255, 0.1);
            height: 100%;
            text-align: center;
            display: block;
            padding: 25px 0;

            button {
                background-color:#52bf52;
                border: none;
                // background-image: url('./../assets/img/personal-center/long-button-bg.png');
                // background-repeat: no-repeat;
                // background-size: 100% 100%;
                height: 35px;
                width: 30%;
                line-height: 35px;

                &.disabled {
                    background-color: rgba(87, 86, 84, 0.8);
                }
            }
        }

    }
</style>
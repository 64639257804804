<template>
    <div class="register">
        <Spinner v-if="isSpinner" :msg='spinMessage' :status="failResponse" />

        <div class="customer-service"></div>
        <div class="form">
            <div class="form-group">
                <div class="icon-cont">
                    <div class="icon user"></div>
                </div>
                <input autocapitalize="none" type="text" v-model="username" v-on:keyup="checkInput()">
                <div class="placeholder" v-if="username == ''">4-12 chữ cái hoặc số</div>
                <div class="icon remove" @click="username=''" v-if="username!=''"></div>
            </div>
            <div class="form-group">
                <div class="icon-cont">
                    <div class="icon zalo"></div>
                </div>
                <input autocapitalize="none" type="text" v-model="zalo" v-on:keyup="checkInput()">
                <div class="placeholder" v-if="zalo == ''"></div>
                <div class="icon remove" @click="zalo=''" v-if="zalo!=''"></div>
            </div>
            <div class="form-group">
                <div class="icon-cont">
                    <div class="icon password"></div>
                </div>
                <input :type="passwordType" v-model="password" v-on:keyup="checkInput()" autocomplete="new-password">
                <div class="placeholder" v-if="password==''">6-12 chữ số và chứa sự kết hợp của các chữ cái và số</div>
                <div class="icon remove" @click="password=''" v-if="password!=''"></div>
                <div class="icon eye" @click="passwordTypeChange" v-bind:class="{open : passwordType=='text'}"></div>
            </div>
            <div class="form-group">
                <div class="icon-cont">
                    <div class="icon password"></div>
                </div>
                <input :type="passwordType" v-model="confirmPassword" v-on:keyup="checkInput()">
                <div class="placeholder" v-if="confirmPassword==''">Nhập lại mật khẩu</div>
                <div class="icon remove" @click="confirmPassword=''" v-if="confirmPassword!=''"></div>
                <div class="icon eye" @click="passwordTypeChange" v-bind:class="{open : passwordType=='text'}"></div>
            </div>
            <div class="form-group" @click="captcha=true">
                <div class="icon-cont">
                    <div class="icon verify" v-bind:class="captchaStatus"></div>
                </div>
                <div class="placeholder">Nhấp vào nút để xác minh</div>
            </div>
            <button @click="register()" :disabled="btnDisabled" v-bind:class="{ disabled: btnDisabled }">Đăng
                ký</button>
            <p>
                <router-link to="/">Đã có tài khoản</router-link>
            </p>
        </div>
        <div class="captcha" v-if="captcha">
            <div class="dim" @click="captcha=false"></div>
            <slide-verify :l="42" :r="10" :w="310" :h="155" slider-text="Vuốt sang phải" @success="onSuccess" @fail="onFail"
                @refresh="onRefresh"></slide-verify>
        </div>
    </div>
</template>
<script>
    const axios = require('axios')
    const config = require('./../config/index.js')
    import Spinner from './../components/Spinner.vue'
    export default {
        components: {
            Spinner
        },
        data() {
            return {
                username: '',
                zalo: '',
                password: '',
                confirmPassword: '',
                msg: '',
                captcha: false,
                captchaAuth: false,
                captchaStatus: null,
                btnDisabled: true,
                isSpinner: false,
                spinMessage: '',
                failResponse: false,
                passwordType: "password",
                passwordType2: "password"
            }
        },
        mounted() {

            if (this.$store.state.isLoggedIn) {
                this.$router.push('/personal-center/withdraw')
            }
        },
        methods: {
            passwordTypeChange() {
                if (this.passwordType == 'text') {
                    this.passwordType = "password"
                } else {
                    this.passwordType = "text"
                }
            },
            checkInput() {
                if (this.username == '' || this.password == '' || this.confirmPassword == '' || this.captchaAuth ==
                    false) {
                    this.btnDisabled = true
                } else {
                    if (this.password == this.confirmPassword) {
                        this.btnDisabled = false
                    } else {
                        this.btnDisabled = true
                    }
                }
            },
            onSuccess() {
                this.captchaAuth = true
                this.captchaStatus = "verified"
                this.checkInput()
                setTimeout(() => {
                    this.captcha = false
                }, 1000);
            },
            onFail() {
                this.captchaStatus = "error"
            },
            onRefresh() {
                this.msg = ''
            },
            register() {
                var self = this
                self.isSpinner = true

                var base =
                    config.accountAuth.baseApi + '?vendor=' + config.accountAuth.vendor + '&cmd=' + config.accountAuth
                    .registerCMD + '&username=' + this.username + '&password=' + this.password + '&zalo=' + this.zalo


                axios.get(base)
                    .then(function (response) {
                        // handle success

                        if (response.data.errorCode) {
                            self.isSpinner = true
                            self.failResponse = true
                            self.spinMessage = response.data.errorMessage

                            setTimeout(() => {
                                self.failResponse = false
                                self.isSpinner = false
                                self.spinMessage = ""
                            }, 2000);
                        } else {
                            self.isSpinner = false
                            self.$store.dispatch('saveLogin', response.data.result)
                            self.$router.push('/personal-center/withdraw')
                        }

                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error);
                    });
            }
        }
    }
</script>
<style lang="less" scoped>
    .register {
        text-align: center;
        height: 100vh;
        background-color: #555;
        padding: 0 25%;
        background-image: url('./../assets/img/games/casino-software.jpg');
        background-repeat: no-repeat;
        background-size: cover;

        .form {
            text-align: center;
            font-size: 12px;
            position: relative;
            top: 50%;
            transform: translateY(-50%);

            .form-group {
                margin-bottom: 10px;
                position: relative;
                width: 100%;
                height: 36px;
                line-height: 40px;
                border-radius: 5px 10px 10px 5px;
                background-color: rgba(255, 255, 255, .3);

                input {
                    font-size: 21px;
                    outline: none;
                    color: #333;
                    width: 70%;
                    height: 100%;
                    border: none;
                    background-color: transparent;
                    position: absolute;
                    left: 0;
                    width: 100%;
                    padding-left: 50px;
                    z-index: 1;
                    display: inline-block;
                }

                .placeholder {
                    padding-left: 50px;
                    z-index: 0;
                    color: #999;
                    text-align: left;
                    width: 85%;
                    height: 100%;
                    border: none;
                    background-color: transparent;
                    position: absolute;
                    left: 0;
                    display: inline-block;
                    font-size: 13px;
                }

                .icon-cont {
                    position: absolute;
                    left: 0;
                    top: 0;
                    height: 100%;
                    width: 45px;
                    background-color: #ddd;
                    border-top-left-radius: 5px;
                    border-bottom-left-radius: 5px;

                    .icon {
                        background-position: center;
                        width: 15px;
                        top: 50%;
                        transform: translateY(-50%);
                        margin: auto;
                        right: 0;
                        left: 0;
                        position: relative;
                        height: 15px;
                        background-repeat: no-repeat;
                        background-size: contain;

                        &.user {
                            background-image: url('./../assets/icons/entry_user_icon.png');
                        }

                        &.password {
                            background-image: url('./../assets/icons/entry_password_icon.png');
                        }

                        &.verify {
                            background-image: url('./../assets/icons/entry_verify_icon.png');
                        }

                        &.zalo {
                            background-image: url('./../assets/icons/zalo.png');
                            height: 40px;
                            width: 40px;
                        }
                    }
                }

                .icon {
                    background-position: center;
                    width: 15px;
                    top: 50%;
                    transform: translateY(-50%);
                    margin: auto;
                    right: 0;
                    left: 0;
                    position: relative;
                    height: 15px;
                    background-repeat: no-repeat;
                    background-size: contain;

                    &.remove {
                        background-image: url('./../assets/icons/entry_clear_icon.png');
                        right: 15px;
                        z-index: 1;
                        position: absolute;
                        left: initial;
                    }

                    &.eye {
                        background-image: url('./../assets/icons/eye-close.svg');
                        position: absolute;
                        right: -25px;
                        left: initial;
                        height: 20px;
                        width: 20px;
                        background-color: rgba(255, 255, 255, .3);
                        border-radius: 100px;
                        z-index: 1;
                        &.open {
                            background-image: url('./../assets/icons/eye-open.svg');
                        }
                    }
                }
            }

            .row {
                display: flex;

                .col {
                    width: 50%;
                    float: left;

                    &:last-child {
                        text-align: right;
                    }
                }
            }

            button {
                width: 100%;
                height: 40px;
                line-height: 40px;
                border: none;
                border-radius: 0;
                font-size: 12px;
                color: #fff;
                outline: none;
                opacity: 1;
                background-color: #52bf52;
                border-radius: 10px;
                // background-image: url('./../assets/img/games/button-bg.png');
                background-repeat: no-repeat;
                background-size: 100% 100%;

                &.disabled {
                    opacity: .5;
                }
            }

            p {
                text-align: left;
                margin-top: 10px;

                a {
                    color:#00a500;
                    text-decoration: none;
                    font-size: 16px;
                }
            }
        }
    }

    .captcha {
        position: fixed;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;

        .dim {
            position: fixed;
            height: 100%;
            width: 100%;
            background-color: rgba(0, 0, 0, 0.5);
        }

        .slide-verify {
            left: 0;
            right: 0;
            margin: auto;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .customer-service {
        position: fixed;
        right: 10px;
        bottom: 10px;
        height: 26px;
        width: 26px;
        background-image: url('./../assets/icons/customer-service.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;

    }
    @media only screen and (max-width: 768px) and (orientation : portrait)  {
     .register {
         padding: 0 10% 0 5%;
        width: 85%;
         .form {
             .form-group {
                 input { width: 85%; }
                 .placeholder {
                     width: 85%;
                 }
             }
         }
     }
    }
    // @media (orientation: portrait) {
    //     .register{
    //         padding: 0 !important;
    //         width: 100vh;
    //         height: 100vw;
    //         position: relative;
    //         .form{
    //             margin: 0 25%;
    //         }
    //         .customer-service{
    //             position: absolute;
    //         }
    //     }
    // }
</style>

<template>
    <div class="login">
        <Spinner v-if="isSpinner" :msg='spinMessage' :status="failResponse" />
        <div class="forget-popup" v-if="forgetPopUpShow">
            <div class="dim" @click="forgetPopUpShow=false"></div>
            <div class="content">
                <div class="body">
                    quên mật khẩu
                </div>
                <div class="footer">
                    <ul>
                        <li><router-link to="/forgot-password-email">Email khôi phục mật khẩu</router-link></li>
                        <li><router-link to="/forgot-password">Di động khôi phục mật khẩu</router-link></li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="customer-service"></div>
        <div class="form">
            <div class="form-group">
                <div class="icon-cont">
                    <div class="icon user"></div>
                </div>
                <input type="text" autocapitalize="none" v-model="username" @input="checkInput()">
                <div class="placeholder" v-if="username==''">Tên đăng nhập</div>
                <div class="icon remove" @click="clear('username')" v-if="username!=''"></div>
            </div>
            <div class="form-group">
                <div class="icon-cont">
                    <div class="icon password"></div>
                </div>
                <input :type="passwordType" v-model="password" @input="checkInput()" autocomplete="new-password">
                <div class="placeholder" v-if="password==''">Mật khẩu</div>
                <div class="icon remove"  @click="clear('password')" v-if="password!=''"></div>
                <div class="icon eye" @click="passwordTypeChange" v-bind:class="{open : passwordType=='text'}"></div>
            </div>
            <div class="row">
                <div class="col">
                </div>
                <div class="col">
                    <!-- <p>
                        <router-link to="/forgot-password-email">Email khôi phục mật khẩu</router-link>
                    </p>
                    <p>
                        <router-link to="/forgot-password">Di động khôi phục mật khẩu</router-link>
                    </p> -->
                    <p @click="forgetPopUpShow=true">quên mật khẩu</p>
                </div>
            </div>

            <button :disabled="btnDisabled" v-bind:class="{ disabled: btnDisabled }" @click="login()">Đăng nhập</button>
            <div class="lower-links">
                <div class="row">
                    <div class="col">
                        <p class="register_">
                            <router-link to="/register">Đăng ký tài khoản mới</router-link>
                        </p>
                    </div>
                    <div class="col">
                        <p class="trygame_">
                            <router-link to="/demo">Bản giới thiệu</router-link>
                        </p>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
    const axios = require('axios')
    const config = require('./../config/index.js')
    import Spinner from './../components/Spinner.vue'
    export default {
        components: {
            Spinner
        },
        data() {
            return {
                forgetPopUpShow: false,
                username: '',
                password: '',
                btnDisabled: true,
                isSpinner: false,
                spinMessage: '',
                failResponse: false,
                passwordType: "password"
            }
        },
        mounted() {
            if (this.$store.state.isLoggedIn) {
                this.$router.push('/')
            }
        },
        methods: {
            clear(field){
                if(field == 'username'){
                    this.username = ''
                } else if (field == 'password') {
                    this.password = ''
                }
                this.btnDisabled = true
            },
            passwordTypeChange() {
                if (this.passwordType == 'text') {
                    this.passwordType = "password"
                } else {
                    this.passwordType = "text"
                }
            },
            checkInput() {
                if (this.username == '' || this.password == '') {
                    this.btnDisabled = true
                } else {
                    this.btnDisabled = false
                }
            },
            login() {

                var self = this
                self.isSpinner = true

                var base =
                    config.accountAuth.baseApi + '?vendor=' + config.accountAuth.vendor + '&cmd=' + config.accountAuth
                    .loginCMD + '&username=' + this.username + '&password=' + this.password

                axios.get(base)
                    .then(function (response) {
                        // handle success

                        if (response.data.errorCode) {
                            self.isSpinner = true
                            self.failResponse = true
                            self.spinMessage = response.data.errorMessage

                            setTimeout(() => {
                                self.failResponse = false
                                self.isSpinner = false
                                self.spinMessage = ""
                            }, 2000);
                        } else {
                            self.isSpinner = false
                            self.$store.dispatch('saveLogin', response.data.result)
                            self.$router.push('/personal-center/information')
                        }

                    })
                    .catch(function (error) {
                        self.isSpinner = true
                        self.failResponse = true
                        self.spinMessage = error

                        setTimeout(() => {
                            self.failResponse = false
                            self.isSpinner = false
                            self.spinMessage = ""
                        }, 2000);
                    });


            }
        }
    }
</script>
<style lang="less" scoped>
    .login {
        text-align: center;
        height: 100vh;
        padding: 0 25%;
        background-image: url('./../assets/img/games/casino-software.jpg');
        background-repeat: no-repeat;
        background-size: cover;

        .form {
            position: relative;
            top: 50%;
            transform: translateY(-50%);
            text-align: center;
            font-size: 12px;

            .form-group {
                margin-bottom: 10px;
                position: relative;
                width: 100%;
                height: 40px;
                line-height: 40px;
                border-radius: 5px 10px 10px 5px;
                background-color: rgba(255, 255, 255, .3);

                input {
                    font-size: 21px;
                    outline: none;
                    color: #333;
                    padding-left: 2px;
                    height: 100%;
                    border: none;
                    background-color: transparent;
                    position: absolute;
                    left: 0;
                    width: 79%;
                    z-index: 1;
                    display: inline-block;
                    border-radius: 5px;
                    margin-left: 46px;
                    padding-left: 5px;
                }

                .placeholder {
                    z-index: 0;
                    color:#999;
                    text-align: left;
                    width: 70%;
                    padding-left: 50px;
                    height: 100%;
                    border: none;
                    background-color: transparent;
                    position: absolute;
                    left: 0;
                    width: 100%;
                    display: inline-block;
                    font-size: 14px;
                }

                .icon-cont {
                    position: absolute;
                    left: 0;
                    top: 0;
                    height: 100%;
                    width: 45px;
                    border-top-left-radius: 5px;
                    border-bottom-left-radius: 5px;
                    background-color: #ddd;

                    .icon {
                        &.user {
                            background-image: url('./../assets/icons/entry_user_icon.png');
                        }

                        &.password {
                            background-image: url('./../assets/icons/entry_password_icon.png');
                        }

                        &.verify {
                            background-image: url('./../assets/icons/entry_verify_icon.png');
                        }
                    }
                }

                .icon {
                    background-position: center;
                    width: 15px;
                    top: 50%;
                    transform: translateY(-50%);
                    margin: auto;
                    right: 0;
                    left: 0;
                    position: relative;
                    height: 15px;
                    background-repeat: no-repeat;
                    background-size: contain;

                    &.remove {
                        background-image: url('./../assets/icons/entry_clear_icon.png');
                        right: 15px;
                        z-index: 1;
                        position: absolute;
                        left: initial;
                    }

                    &.eye {
                        background-image: url('./../assets/icons/eye-close.svg');
                        position: absolute;
                        right: -25px;
                        left: initial;
                        background-color: rgba(255,255,255,.3);
                        border-radius: 100px;
                        z-index: 1;
                        height: 20px;
                        width: 20px;

                        &.open {
                            background-image: url('./../assets/icons/eye-open.svg');
                        }
                    }
                }
            }

            p {

                margin-bottom: 10px;
                color: #333;

                a {
                    text-decoration: none;
                    color: rgba(255, 255, 255, .3);
                    font-size: 12px;
                }
            }
            .register_{
                a {
                    text-decoration: none;
                    font-size: 15px;
                    color: #00a500;
                }
            }
            .trygame_{
                a {
                    text-decoration: none;
                    font-size: 12px;
                    color: #00a500;
                }
            }

            .row {
                display: flex;

                .col {
                    margin: 0;
                    width: 50%;
                    float: left;
                    text-align: left;
                    p{
                        display: inline-block;
                        padding: 0 10px;
                    }

                    &:last-child {
                        text-align: right;
                    }
                }
            }

            .lower-links {
                width: 100%;
                margin-top: 10px;
                display: inline-block;
            }

            button {
                width: 100%;
                height: 40px;
                line-height: 40px;
                border: none;
                border-radius: 0;
                font-size: 12px;
                color: #fff;
                background-color: #52bf52;
                opacity: 1;
                outline: none;
                // background-image: url('./../assets/img/games/button-bg.png');
                background-repeat: no-repeat;
                background-size: 100% 100%;
                border-radius: 10px;

                &.disabled {
                    opacity: .5;
                }
            }
        }
    }

    .customer-service {
        position: fixed;
        right: 10px;
        bottom: 10px;
        height: 26px;
        width: 26px;
        background-image: url('./../assets/icons/customer-service.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;

    }
    .forget-popup {
        height: 100vh;
        width: 100%;
        position: fixed;
        z-index: 1;
        top: 0;
        left: 0;
        .dim{
            height: 100vh;
            width: 100%;
            position: fixed;
            background-color: rgba(25, 22, 22, 0.7);
            top: 0;
            left: 0;
            
        }
        .content {
            border-radius: 18px;
            text-align: center;
            color: #000;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
            width: 55%;
            top: 50%;
            background-color: #ccc;
            transform: translateY(-50%);

            .body {
                font-size: 20px;
                padding: 20px 10px;
            }

            .footer {

                ul {
                    li {
                        width: 50%;
                        background-color: rgba(255, 255, 255, 0.56);
                        height: 40px;
                        float: left;
                        list-style-type: none;
                        line-height: 40px;
                        border-radius: 15px;
                        a{
                            color: #111;
                            text-decoration: none;
                        }
                    }
                }
            }
        }
    }
    @media only screen and (max-width: 768px) and (orientation : portrait)  {
     .login {
         padding: 0 10% 0 5%;
         width: 85%;
         .form {
             .form-group {
                 .placeholder {
                     width: 85%;
                 }
             }
         }
     }
     .forget-popup{
         .content {
             width: 94%;
            .footer {
                ul {
                    li {
                        width: 48%;
                        font-size: 12px;
                        margin: 0px 1%;
                    }
                }
            }
         }
     }
    }
    // @media (orientation: portrait) {
    //     .login{
    //         padding: 0 !important;
    //         width: 100vh;
    //         height: 100vw;
    //         position: relative;
    //         .form{
    //             margin: 0 25%;
    //         }
    //         .customer-service{
    //             position: absolute;
    //         }
    //         .forget-popup{
    //             width: 100vh;
    //         height: 100vw;
    //         .dim{
                
    //             width: 100vh;
    //         height: 100vw;
    //         }
    //         }
    //     }
    // }
</style>

<template>
    <div class="withdrawal-records">
        <Spinner v-if="isSpinner" :msg='spinMessage' :status="failResponse" />
        <LogOutSpinner v-if="isSpinner2" :msg='spinMessage2' :status="failResponse2" />

      <div class="header">
          <h3>Lịch Sử Rút Tiền</h3>
      </div>
        <div class="main-content-withdrawal-records" id="container">
            <ul v-for="(record1, index1) in records" v-bind:key="index1">
                <li v-for="(record, index) in record1" v-bind:key="index">
                    <div class="row">
                        <div class="col">
                            <h5 class="gold">Số tiền</h5>
                        </div>
                        <div class="col">
                            <!-- <h5 class="gold">{{ record.amount*1000 }}</h5> -->
                            <h5 class="gold">{{ record.amount }}</h5>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <p >thời gian</p>
                        </div>
                        <div class="col">
                            <span class="date">{{ record.created_at }}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <p >Mã đơn</p>
                        </div>
                        <div class="col">
                            <span class="date">{{ record.order_no }}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <p >Phí xử lý</p>
                        </div>
                        <div class="col">
                            <span class="date">{{ record.fee }}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <p >Ghi chú</p>
                        </div>
                        <div class="col">
                            <span class="date">{{ record.remarks }}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <p >Trạng thái</p>
                        </div>
                        <div class="col">
                            <span class="date">{{ status[record.status] }}</span>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
const axios = require('axios')
    const config = require('./../config/index.js')
    import Spinner from './../components/Spinner.vue'
    import LogOutSpinner from './../components/LogOutSpinner.vue'
    export default {
        components: {
            Spinner,
            LogOutSpinner
        },
        data() {
            return {
                records: [],
                status: [],
                lastPage: null,
                firstLoad: 0,
                isSpinner: false,
                spinMessage: '',
                failResponse: false,
                isSpinner2: false,
                spinMessage2: '',
                failResponse2: false,
                currentPage: 0
            }
        },
        mounted() {
            this.firstLoad = 1
            this.scroll()
            document.getElementById('container').addEventListener('scroll', this.scroll)
        },
        methods: {
            scroll() {
                var self = this
                var element = document.getElementById('container')
                if (element.scrollTop + element.clientHeight == element.scrollHeight) {
                    if(self.currentPage == 0){
                        self.getWithdrawRecords(this.currentPage + 1)
                    } else {
                        if(self.currentPage < self.lastPage){
                            self.getWithdrawRecords(this.currentPage + 1)
                        }
                    }
                }
            },
            getWithdrawRecords(page) {
                var self = this
                self.isSpinner2 = true
                var base =
                    config.baseApi + '?merchant=' + config.merchant + '&serial=' + config.WithdrawRecordsSerial + '&sign=' +
                    config.sign(config.merchant, config.WithdrawRecordsSerial, config.md5Key) + '&param='

                var param = config.encryptAES('pageSize=15&page=' + page, config.aes)

                axios.get(base + param)
                    .then(function (response) {
                        // handle success
                        
                        if(response.data.success){
                            self.records.push(response.data.data.list)
                        self.status = response.data.data.status
                        self.lastPage = response.data.data.lastPage
                        self.currentPage = self.currentPage + 1
                        self.isSpinner2 = false
                        } else {
                            console.log(response.data)
                        }

                        if(response.data.errorCode == 21){

                            self.isSpinner2 = true
                            self.failResponse2 = true
                            self.spinMessage2 = response.data.errorMessage

                        }


                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error);
                    });
            }
        }
    }
</script>
<style lang="less" scoped>
.header{
            line-height: 40px;
            height: 40px;
            width: 100%;
            background-color: rgba(148, 148, 148, 0.2);
            h3{
                margin-top: 10px;
                color: #333;
                font-size: 14px;
                margin-left: 10px;
            }
        }
    .main-content-withdrawal-records {
        height: 66vh;
        overflow: scroll;
        ul {
            &:nth-child(even){
                
                li{
                color: #d2b79c !important;
                background-color: transparent !important;
                    &:nth-child(odd){
                        color: #fff !important;
                        background-color: rgba(255, 255, 255, .2) !important;
                    }
                }
            }
            li {
                padding: 5px 10px;
                list-style-type: none;
                font-size: 12px;

                color: #023eff;
            &:nth-child(even){
                color: #fff;
                background-color: rgba(255, 255, 255, .2);
            }
                .row {
                    display: flex;

                    .col {
                        line-height: 18px;
                        width: 50%;
                        text-align: left;
                        display: inline-block;
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
</style>
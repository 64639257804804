<template>
    <div class="game-records" id="gamescroll">
        <Spinner v-if="isSpinner" :msg='spinMessage' :status="failResponse" />
        
      <div class="header">
          <h3>Game Record</h3>
      </div>
        <div class="main-content-game-records" id="container">
            <ul v-for="(record1, index1) in records" v-bind:key="index1">
                <li v-for="(record, index) in record1" v-bind:key="index">
                    <div class="row">
                        <div class="col">
                            <h5 v-if="record.status == 2" class="gold">游戏带入</h5>
                            <h5 v-else-if="record.status == 3">游戏带出</h5>
                        </div>
                        <div class="col">
                            <span class="amount">{{ record.amount }}</span>
                        </div>
                    </div>
                    <p v-if="record.status == 2">游戏厂商 : <span>{{ record.game_name }}</span></p>
                    <div class="row">
                        <div class="col">
                            <p v-if="record.status == 2">游戏名称 : <span>{{ record.game_maker }}</span></p>
                        </div>
                        <div class="col">
                            <span class="date">{{ record.created_at }}</span>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    const axios = require('axios')
    const config = require('./../config/index.js')
    import Spinner from './../components/Spinner.vue'
    export default {
        components: {
            Spinner
        },
        data() {
            return {
                isSpinner: false,
                spinMessage: '',
                failResponse: false,
                records: [],
                currentPage: 1,
                lastPage: null,
                firstLoad: 0
            }
        },
        mounted() {
            this.firstLoad = 1
            this.scroll()
            document.getElementById('container').addEventListener('scroll', this.scroll)
        },
        methods: {
            
            scroll() {
                var self = this
                var element = document.getElementById('container')
                if (element.scrollTop + element.clientHeight == element.scrollHeight) {
                    if(self.currentPage == 0){
                        self.getDepositRecords(this.currentPage + 1)
                    } else {
                        if(self.currentPage < self.lastPage){
                            self.getDepositRecords(this.currentPage + 1)
                        }
                    }
                }
            },
            getGameHistory(page) {

                var self = this
                self.isSpinner = true
                var base =
                    config.baseApi + '?merchant=' + config.merchant + '&serial=' + config.gameHistorySerial + '&sign=' +
                    config.sign(config.merchant, config.gameHistorySerial, config.md5Key) + '&param='

                var param = config.encryptAES('pageSize=15&page=' + page, config.aes)

                axios.get(base + param)
                    .then(function (response) {
                        // handle success
                        self.records.push(response.data.data.list)
                        self.lastPage = response.data.data.lastPage
                        self.status = response.data.data.status
                        self.currentPage = self.currentPage + 1
                        self.isSpinner = false
                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error);
                    });
            }
        }
    }
</script>
<style lang="less" scoped>
    .header{
            line-height: 40px;
            height: 40px;
            width: 100%;
            background-color: rgba(130, 188, 255, 0.15);
            h3{
                margin-top: 10px;
                color: #FFF;
                font-size: 14px;
                margin-left: 10px;
            }
        }
    .main-content-game-records {
        height: 66vh;
        overflow: scroll;
        ul {
            &:nth-child(even){
                
                li{
                color: #d2b79c !important;
                background-color: transparent !important;
                    &:nth-child(odd){
                        color: #fff !important;
                        background-color: rgba(255, 255, 255, .2) !important;
                    }
                }
            }
            li {
                padding: 5px 10px;
                list-style-type: none;
                font-size: 12px;

                color: #d2b79c;
            &:nth-child(even){
                color: #fff;
                background-color: rgba(255, 255, 255, .2);
            }
                .row {
                    display: flex;

                    .col {
                        line-height: 18px;
                        width: 50%;
                        text-align: left;
                        display: inline-block;
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
</style>
import { render, staticRenderFns } from "./GameRecord.vue?vue&type=template&id=4c357466&scoped=true&"
import script from "./GameRecord.vue?vue&type=script&lang=js&"
export * from "./GameRecord.vue?vue&type=script&lang=js&"
import style0 from "./GameRecord.vue?vue&type=style&index=0&id=4c357466&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c357466",
  null
  
)

export default component.exports
<template>
    <div class="personal-center">
        <LogOutSpinner v-if="isSpinner2" :msg='spinMessage2' :status="failResponse2" />
        <div id="nav-center">
            <!-- <div class="return"> -->
                 <!-- @click="retur()" -->
                 <!-- remove for a while -->
                <!-- <div class="icon"></div>
                <div class="text">Sảnh trò chơi</div>
            </div> -->
            <div class="navbar">
                <div class="title"></div>
                <ul class="right-menu" v-if="false">
                    <li>
                        <router-link to="/customer-service">Customer Service</router-link>
                    </li>
                    <li @click="logout()">Log out</li>
                </ul>
            </div>
        </div>
        <div class="main-content">
            <div class="sidebar">
                <ul>
                    <li>
                        <router-link to="/personal-center/information">
                            <div class="icon personal"></div> <span>Trung tâm cá nhân</span>
                        </router-link>
                    </li>
                    <li v-if="false">
                        <router-link to="/personal-center/security">
                            <div class="icon security"></div> <span>Security Center</span>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/personal-center/bank-card">
                            <div class="icon bank"></div> <span>Quản lý thẻ ngân hàng</span>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/personal-center/withdraw">
                            <div class="icon withdraw"></div> <span>Số tiền rút</span>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/personal-center/withdrawal-record">
                            <div class="icon withdrawal"></div> <span>Lịch Sử Rút Tiền</span>
                        </router-link>
                    </li>
                    <!-- <li>
                        <router-link to="/personal-center/billing-record">
                            <div class="icon billing"></div> <span>Lịch sử Đơn</span>
                        </router-link>
                    </li> -->
<!--                    <li>-->
<!--                        <router-link to="/personal-center/game-record">-->
<!--                            <div class="icon game"></div> <span>Game Record</span>-->
<!--                        </router-link>-->
<!--                    </li>-->
                </ul>
            </div>
            <div class="content">
                <router-view />
            </div>
        </div>
    </div>
</template>
<script>
const axios = require('axios')
    const config = require('./../config/index.js')
    import LogOutSpinner from './../components/LogOutSpinner.vue'
export default {
    components:{
        LogOutSpinner
    },
    data(){
        return{

                isSpinner2: false,
                spinMessage2: '',
                failResponse2: false
        }
    },
    mounted(){
        if(localStorage.getItem("stayTime") === null){
            sessionStorage.setItem("stayTime", new Date());
        }
    },
    methods: {
        retur(){

                var self = this
            var stay = new Date(sessionStorage.getItem("stayTime"))
            var newTime = new Date()
            var seconds = Math.abs(stay - newTime) / 1000
            if(seconds > 5){
                self.isSpinner2 = true

                var base =
                    config.accountAuth.baseApi + '?vendor=' + config.accountAuth.vendor + '&cmd=' + config.accountAuth
                    .checkLoginCMD

                axios.get(base)
                    .then(function (response) {
                        // handle success

                        if(response.data.errorCode){
                            if(response.data.errorCode == 12){

                            self.isSpinner2 = true
                            self.failResponse2 = true
                            self.spinMessage2 = response.data.errorMessage

                        }
                        } else {
                self.isSpinner2 = false
                            self.$store.dispatch('saveLogin', response.data.result)
                            self.$router.push('/')
                        }

                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error);
                    });

            } else {
                self.$router.push('/')
            }
        }
    }
}
</script>

<style lang="less" scoped>
    .personal-center {

        background-image: url('./../assets/img/games/casino-software.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        height: 100vh;

        #nav-center {
            height: 15vh;
            position: relative;

            .return {
                z-index: 1;
                width: 30%;
                height: 100%;
                background-image: url('./../assets/img/personal-center/return-cont.png');
                background-repeat: no-repeat;
                display: inline-block;
                background-size: 100% 100%;
                position: absolute;
                top: 0;
                left: 0;
                text-transform: capitalize;

                .icon {
                    width: 60%;
                    position: absolute;
                    top: 50%;
                    left: 5%;
                    transform: translateY(-50%);
                    height: 50%;
                    background-image: url('./../assets/img/personal-center/return-icon.png');
                    background-repeat: no-repeat;
                    display: inline-block;
                    background-size: contain;
                }

                .text {
                    height: 30%;
                    position: absolute;
                    width: 60%;
                    color: #d2b79c;
                    font-size: 16px;
                    top: 50%;
                    transform: translateY(-50%);
                    vertical-align: middle;
                    right: 10%;
                }
            }

            .navbar {
                width: 100%;
                height: 75%;
                z-index: 0;
                font-size: 75%;
                background-image: url('./../assets/img/personal-center/nav.png');
                background-repeat: no-repeat;
                background-size: cover;
                position: relative;
                background: #16cc00;
                border-bottom: 2px solid #ddd;

                .title {
                    color: #FFF;
                    position: absolute;
                    left: 28%;
                    top: 50%;
                    transform: translateY(-50%);
                }

                ul {
                    position: absolute;
                    right: 20px;
                    top: 50%;
                    transform: translateY(-50%);

                    li {
                        color: #FFF;
                        float: left;
                        list-style-type: none;

                        a {
                            padding: 0 10px;
                            color: #FFF;
                            text-decoration: none;

                        }
                    }

                    &.right-menu {
                        float: right;
                    }
                }
            }
        }

        .main-content {
            display: flex;
            height: 80vh;
            margin-top: 2vh;
            padding: 0 10px;

            .sidebar {
                width: 30%;
                float: left;
                height: 100%;
                padding-right: 10px;

                ul {
                    height: 100%;

                    li {
                        &:last-child {
                            margin-bottom: 0;
                        }

                        // height: 12.84%;
                        height: 13.05%;
                        display: flex;
                        overflow: hidden;
                        list-style-type: none;
                        text-align: center;
                        margin-bottom: 5px;

                        span {
                            position: absolute;
                            top: 50%;
                            transform: translateY(-50%);
                            left: 35%;
                        }

                        a {

                            width: 100%;
                            position: relative;
                            -webkit-user-select: none;
                            -khtml-user-select: none;
                            -moz-user-select: none;
                            -ms-user-select: none;
                            user-select: none;
                            background-image: url('./../assets/img/personal-center/menu-default.png');
                            background-repeat: no-repeat;
                            background-size: 100% 100%;
                            display: block;
                            font-size: 80%;
                            line-height: 100%;
                            text-decoration: none;
                            color: #333;
                            text-transform: capitalize;
                            background: #ddd;
                            border-radius: 10px;
                            border: 3px solid #33cc33;
                            font-weight: bold;

                            &.router-link-exact-active {
                                // background-image: url('./../assets/img/personal-center/menu-active.png');
                                background: #33cc33;
                                color: #fff;
                                 border: 3px solid #ddd;
                                .icon {
                                    &.personal {
                                        background-image: url('./../assets/img/personal-center/personal-icon-white.png');
                                    }

                                    &.security {
                                        background-image: url('./../assets/img/personal-center/security-icon-white.png');
                                    }

                                    &.bank {
                                        background-image: url('./../assets/img/personal-center/bank-card-silver.png');
                                    }

                                    &.recharge {
                                        background-image: url('./../assets/img/personal-center/recharge-icon-white.png');
                                    }

                                    &.withdraw {
                                        background-image: url('./../assets/img/personal-center/withdraw-icon-white.png');
                                    }

                                    &.recharge-record {
                                        background-image: url('./../assets/img/personal-center/recharge-record-icon-white.png');
                                    }

                                    &.withdrawal {
                                        background-image: url('./../assets/img/personal-center/withdraw-record-icon-white.png');
                                    }

                                    &.billing {
                                        background-image: url('./../assets/img/personal-center/billing-record-icon-white.png');
                                    }

                                    &.game {
                                        background-image: url('./../assets/img/personal-center/game-record-icon-white.png');
                                    }

                                }
                            }

                            .icon {
                                width: 100%;
                                height: 50%;
                                background-repeat: no-repeat;
                                background-size: contain;
                                position: absolute;
                                left: 32px;
                                top: 25%;

                                &.personal {
                                    background-image: url('./../assets/img/personal-center/personal-icon-gold.png');
                                }

                                &.security {
                                    background-image: url('./../assets/img/personal-center/security-icon-gold.png');
                                }

                                &.bank {
                                    background-image: url('./../assets/img/personal-center/bank-card-gold.png');
                                }

                                &.recharge {
                                    background-image: url('./../assets/img/personal-center/recharge-icon-gold.png');
                                }

                                &.withdraw {
                                    background-image: url('./../assets/img/personal-center/withdraw-icon-gold.png');
                                }


                                    &.recharge-record {
                                        background-image: url('./../assets/img/personal-center/recharge-record-icon-gold.png');
                                    }

                                    &.withdrawal {
                                        background-image: url('./../assets/img/personal-center/withdraw-record-icon-gold.png');
                                    }

                                    &.billing {
                                        background-image: url('./../assets/img/personal-center/billing-record-icon-gold.png');
                                    }

                                    &.game {
                                        background-image: url('./../assets/img/personal-center/game-record-icon-gold.png');
                                    }
                            }
                        }
                    }
                }
            }

            .content {
                width: 70%;
                height: 100%;
                position: relative;
                float: left;
                // background-image: url('./../assets/img/personal-center/content.png');
                background: rgba(255, 255, 255, .38);
                background-repeat: no-repeat;
                background-size: 100% 100%;
                border: 3px solid #ddd;
                border-radius: 10px;
                overflow: hidden;
            }
        }
    }
    @media (orientation: portrait) {
        // .personal-center{
        //     width: 100vh;
        //     height: 100vw;
        //     #nav-center{
        //         height: 15vw;
        //     }
        //     .main-content{
        //         height: 78vw;
        //     }
        // }
    }
    @media only screen and (max-width: 767px) and (orientation : portrait)  {
        .personal-center {
            .main-content {
                display: block;
                height: auto;
                margin-top: 10px;
                .sidebar {
                    float: none;
                    width: 100%;
                    margin-bottom: 10px;
                    ul {
                        li {
                            height: 50px;
                            display: inline-block;
                            width: 48%;
                            margin: 2px 1%;
                            a {
                            height: 44px;
                            line-height: 110%;
                            width: auto;
                            }
                            &:last-child {
                               margin-bottom: 2px;
                            }
                        }
                    }
                }
                .content {
                    float: none;
                    width: 96%;
                    margin: 0px auto;
                    min-height: 200px;
                }
            }
            #nav-center {
                height: initial;
                margin-bottom: 15px;
                .navbar {
                    height: 50px;
                }
            }
        }
        
     }
    @media only screen and (max-width: 650px )  {
        .personal-center {
            #nav-center {
                .return {
                    .text {
                        width: 85%;
                        text-align: right;
                    }
                }
            }
            .main-content {
                .sidebar {
                    ul {
                        li {
                            a {
                                .icon {
                                    left: 8%;
                                }
                                span{
                                    left: 31% !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
     
</style>

<template>
    <div class="forgot-password">
        <Spinner v-if="isSpinner" :msg='spinMessage' :status="failResponse" />

        <router-link to="/" class="back"></router-link>
        <div class="customer-service"></div>
        <div class="form">

            <div class="form-group">
                <label for="">E-mail</label>
                <input type="email" v-model="email" v-on:keyup="checkInput()">
                <div class="placeholder" v-if="email==''">vui lòng nhập địa chỉ email của bạn</div>
                <div class="icon remove" @click="email=''" v-if="email!=''"></div>
            </div>

            <div class="form-group">
                <label for="">Lấy mã xác nhận</label>
                <input oninput="value=value.replace(/[^\d]/g,'')" type="tel" v-model="code" v-on:keyup="checkInput()">
                <div class="placeholder" v-if="code==''">Vui lòng nhập mã xác nhận</div>
                <button class="get-code" @click="getCode()" :disabled="codeBtnDisabled"
                    v-bind:class="{ disabled: codeBtnDisabled }"> {{ codeString }} </button>
            </div>

            <div class="form-group">
                <label for="">Mật khẩu mới</label>
                <input type="text" v-model="password" v-on:keyup="checkInput()">
                <div class="placeholder" v-if="password==''">Vui lòng nhập mật khẩu mới</div>
                <div class="icon remove" @click="password=''" v-if="password!=''"></div>
            </div>

            <button :disabled="btnDisabled" v-bind:class="{ disabled: btnDisabled }" @click="forgot()">Xác nhận</button>


        </div>
    </div>
</template>
<script>
    const axios = require('axios')
    axios.defaults.withCredentials=true
    const config = require('./../config/index.js')
    import Spinner from './../components/Spinner.vue'
    export default {
        components: {
            Spinner
        },
        data() {
            return {
                code: '',
                email: '',
                password: '',
                btnDisabled: true,
                isSpinner: false,
                spinMessage: '',
                failResponse: false,
                codeBtnDisabled: false,
                codeString: "Lấy mã xác nhận",
                time: 50
            }
        },
        mounted() {

            if(this.$store.state.isLoggedIn){
                this.$router.push('/')
            }

        },
        methods: {
            checkInput() {
                if (this.password == '' || this.code == '') {
                    this.btnDisabled = true
                } else {
                    this.btnDisabled = false
                }
            },
            forgot() {
                var self = this
                self.isSpinner = true

                var base =
                    config.accountAuth.baseApi + '?vendor=' + config.accountAuth.vendor + '&cmd=' + config.accountAuth.checkEmailCode + '&code=' + this.code + '&new_password=' + this.password

                axios.get(base)
                    .then(function (response) {
                        // handle success

                        if(response.data.errorCode){
                            self.isSpinner = true
                            self.failResponse = true
                            self.spinMessage = response.data.errorMessage

                            setTimeout(() => {
                                self.failResponse = false
                                self.isSpinner = false
                                self.spinMessage = ""
                            }, 2000);
                        } else {
                            self.isSpinner = false
                            self.$router.push('/')
                        }

                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error);
                    });
            },
            getCode() {
                if(this.email != ''){
                    this.sendCode()
                }
            },
            sendCode() {
                var self = this
                self.isSpinner = true

                // var initial = this.phone.charAt(0)

                //     if(initial == '0'){
                //         this.phone = this.phone.substr(1)
                //     }

                var base =
                    config.accountAuth.baseApi + '?vendor=' + config.accountAuth.vendor + '&cmd=' + config.accountAuth.sendEmailCode + '&email=' + this.email

                axios.get(base)
                    .then(function (response) {
                        // handle success
                        if(!response.data.success){
                            self.isSpinner = true
                            self.failResponse = true
                            self.spinMessage = response.data.message

                            setTimeout(() => {
                                self.failResponse = false
                                self.isSpinner = false
                                self.spinMessage = ""
                            }, 2000);
                        }

                        if(response.data.errorCode){
                            self.isSpinner = true
                            self.failResponse = true
                            self.spinMessage = response.data.errorMessage

                            setTimeout(() => {
                                self.failResponse = false
                                self.isSpinner = false
                                self.spinMessage = ""
                            }, 2000);
                        } else {

                            self.isSpinner = true
                            self.failResponse = true
                            self.spinMessage = response.data.result
                            self.codeBtnDisabled = true

                            setTimeout(() => {
                                self.failResponse = false
                                self.isSpinner = false
                                self.spinMessage = ""
                            }, 2000);

                            var inter = setInterval(() => {
                                self.time = self.time - 1
                                self.codeString = self.time + 's'
                                if (self.time == 0) {
                                    self.codeBtnDisabled = false
                                    self.codeString = "Lấy mã xác nhận"
                                    self.time = 50
                                    clearInterval(inter)
                                }
                            }, 1000);
                        }

                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error);
                        self.isSpinner = false
                    });

            }
        }
    }
</script>
<style lang="less">
    .forgot-password {
        text-align: center;
        height: 100vh;
        padding: 0 20%;
        background-image: url('./../assets/img/games/casino-software.jpg');
        background-repeat: no-repeat;
        background-size: cover;

        .form {
            position: relative;
            top: 50%;
            transform: translateY(-50%);
            text-align: center;
            font-size: 12px;
            padding: 15px 10px;
            background-color: rgba(255, 255, 255, 0.38);
            border: 3px solid #ddd;
            border-radius: 10px;

            .form-group {
                margin-bottom: 5px;
                position: relative;
                width: 100%;
                height: 50px;
                line-height: 45px;
                border-bottom: 1px solid #9e9e9e;

                label {
                    color: #333;
                    position: absolute;
                    top: -5px;
                    left: 0;
                }

                input {
                    font-size: 17px;
                    outline: none;
                    color: #333;
                    width: 100%;
                    height: 30px;
                    border: none;
                    background-color: transparent !important;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    margin: auto;
                    z-index: 1;
                    line-height: 35px;
                    display: inline-block;
                }

                .placeholder {
                    z-index: 0;
                    color: #999;
                    text-align: left;
                    width: 100%;
                    line-height: 30px;
                    height: 30px;
                    border: none;
                    background-color: transparent;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    margin: auto;
                    display: inline-block;
                }

                .get-code {
                    height: 35px;
                    width: 120px;
                    position: absolute;
                    line-height: 35px;
                    right: 0;
                    z-index: 1;
                    bottom: 10px;
                   background-color: #52bf52;
                    color: #FFF;
                    text-align: center;
                    border-radius: 5px;
                }

                .vue-tel-input {
                    position: absolute;
                    z-index: 2;
                    width: 100%;
                    height: 30px;
                    line-height: 30px;
                    left: 0;
                    bottom: 0;
                    background-color: transparent;
                    border: none;
                    outline: none;
                    box-shadow: none;

                    input {
                        outline: none;

                        &:focus {
                            outline: none;
                            outline: 0;
                            box-shadow: none !important;
                            background-color: transparent;
                        }
                    }

                    span {
                        display: inline-block;
                    }
                    .vti__dropdown{
                        z-index: 1;
                    }
                    .vti__flag {
                        display: inline-block;
                    }

                    .vti__selection {
                        display: flex;
                    }

                    .vti__input {
                        background-color: transparent !important;
                        position: relative;
                    }
                }
            }

            button {
                width: 100%;
                height: 40px;
                line-height: 40px;
                border: none;
                // border-radius: 0;
                font-size: 12px;
                color: #fff;
                opacity: 1;
                // background-image: url('./../assets/img/games/button-bg.png');
                background-repeat: no-repeat;
                background-size: 100% 100%;
                background-color: #52bf52;
                outline: none;
                border-radius: 10px;
                &.disabled {
                    opacity: .5;
                }
            }
        }
    }

    .customer-service {
        position: fixed;
        right: 20px;
        bottom: 20px;
        height: 26px;
        width: 26px;
        background-image: url('./../assets/icons/customer-service.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }

    .back {
        position: fixed;
        left: 20px;
        top: 20px;
        height: 28px;
        width: 24px;
        background-image: url('./../assets/icons/back.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }
    @media only screen and (max-width: 768px) and (orientation : portrait)  {
        .forgot-password {
            padding: 0 5%;
        }
    }
    @media only screen and (max-width: 414px) and (orientation : portrait)  {
        .forgot-password {
            padding: 0 5%;
        }
        .vti__dropdown-list {
            width: 350px im !important;
        }
    }
    // @media (orientation: portrait) {
    //     .forgot-password{
    //         padding: 0 !important;
    //         width: 100vh;
    //         height: 100vw;
    //         position: relative;
    //         .form{
    //             margin: 0 25%;
    //         }
    //         .customer-service{
    //             position: absolute;
    //         }
    //     }
    // }
</style>

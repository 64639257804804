<template>
  <div class="spinner">
    <div class="bg" v-bind:class="{ 'with-text': status }">
        <img :src="loaderIcon(status)" v-if="!status" v-bind:class="{ animate: animateLoader }" alt="" >
        <p>{{ msg }}</p>
    </div>
  </div>
</template>

<script>
  import warning from '../assets/icons/triangle.svg'
  import loader from '../assets/icons/loader-refresh.png'
export default {
  name: 'Spinner',
  props: {
    msg: String,
    status: Boolean
  },
  data() {
    return{
      animateLoader: false
    }
  },
  mounted() {
  },
  methods: {
    loaderIcon (status) {
      if(status){
        this.animateLoader = false
        return warning
      }
        
      this.animateLoader = true
      return loader
        
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.spinner {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2400;
    background-color: rgba(0, 0, 0, 0.5);

    .bg {
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      margin: auto;
      width: 70%;
      text-align: center;
      // background-color: rgba(0, 0, 0, 0.7);
      padding: 0 5px;

      img {
        margin-top: 20px;
        width: 40px;
        &.animate{
          -webkit-animation:spin 2s linear infinite;
    -moz-animation:spin 2s linear infinite;
    animation:spin 2s linear infinite;
        }
      }

      p {
        vertical-align: middle;
        line-height: 20px;
        font-weight: bold;
      }
      &.with-text{
        padding: 30px 20px;
        background-color: #eddbc7;
      border-radius: 10px;
      color: #8a837d;
      }
    }
  }

  @-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }


    @media (orientation: portrait) {
        // .spinner{
        //     width: 100vh;
        //     height: 100vw;
        // }
    }

</style>

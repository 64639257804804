<template>
    <div class="bank-card-management">

        <Spinner v-if="isSpinner" :msg='spinMessage' :status="failResponse" />
        <LogOutSpinner v-if="isSpinner2" :msg='spinMessage2' :status="failResponse2" />
        <div class="no-bank" v-if="firstLoad == 1">
            <img src="./../assets/img/personal-center/group-cards.png" alt="">
            <button @click="addNewBank()">Thêm thẻ ngân hàng</button>
        </div>
        <div class="with-bank" v-if="firstLoad == 2">
            <div class="header">
                <h3>Quản Lý Thẻ Ngân Hàng</h3>
                <button @click="addNewBank()"></button>
            </div>
            <div class="bank-cards">
                <div class="card-cont" v-for="(card, index) in cards" v-bind:key="index">
                    <div class="card">
                        <div class="icon">
                            <img :src="getImg(card.icon)" alt="">
                        </div>
                        <h4>{{ card.bank }}</h4>
                        <div class="card-name">{{ card.name }}</div>
                        <div class="card-number">{{ card.card_no }}</div>
                        <div class="edit" @click="edit(card.card_id)"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="add-card-1" v-if="firstLoad == 3">
            <div class="header">
                <!--                <h3>Quản Lý Thẻ Ngân Hàng</h3>-->
            </div>
            <div class="form">
                <div class="row">
                    <div class="col">
                        <label for="">Tên trên thẻ</label>
                    </div>
                    <div class="col">
                        <!-- <input type="text" v-model="cardInput.name" v-if="cardNameShow" readonly @input="checkInput()">
                        <input type="text" v-model="cardInput.name" v-if="!cardNameShow"> -->
                        <input type="text" v-model="cardInput.name" @input="checkInput()">
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <label for="">Số Tài khoản Thẻ</label>
                    </div>
                    <div class="col">
                        <!-- <input type="tel" pattern="[0-9]*" maxlength="19" v-model="cardInput.cardNo"
                            @input="checkInput()" v-on:keypress="isNumber($event, cardInput.cardNo)"> -->
                        <input type="text" v-model="cardInput.cardNo"
                            @input="checkInput()">
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <label for="">Chọn Ngân hàng</label>
                    </div>
                    <div class="col">
                        <div class="custom-select" @click="popupShow=true">
                            <img :src="getImg(cardInput.selectedBankID)" alt="" v-if="cardInput.selectedBankID != ''">
                            <div class="bank-name" v-if="cardInput.selectedBank != ''">{{ cardInput.selectedBank }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <label for="">Tên Ngân Hàng</label>
                    </div>
                    <div class="col">
                        <input type="text" v-model="cardInput.bankAddress" @input="checkInput()">
                        <p>Tài khoản mở ngân hàng cần chính xác và ràng buộc tên thật. Tiền sẽ được chuyển trực tiếp vào
                            tài khoản này trong quá trình thanh toán. Để bảo mật cho khoản thanh toán của bạn, tài khoản
                            không thể thay đổi sau khi ràng buộc. Nếu bạn cần sửa đổi nó, xin vui lòng liên hệ với nhân
                            viên dịch vụ khách hàng</p>

                        <div class="btn-cont">
                            <button @click="back()">Bước trước</button>
                            <button v-bind:class="{ disabled: nextBtn }" :disabled="nextBtn" @click="next()">Bước tiếp
                                theo</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="add-card-2" v-if="firstLoad == 4">
            <!--            <div class="header">-->
            <!--                <h3>Quản Lý Thẻ Ngân Hàng</h3>-->
            <!--            </div>-->
            <div class='bindopt'>
                <div class='boptions' v-if='mobileshow'><input type="radio" id="mobile" name="bopt"
                        :value="mobiledisplay" v-model="bindopt" @click="bindoption=1">
                    số điện thoại : <label for="mobile">{{mobiledisplay}}</label></div>
                <!-- <div class='boptions' v-else>
                    <router-link :to="{ path: '/personal-center/information',query: { bind: 'phone' }}" tag="button">
                        Bind Phone</router-link>
                </div> -->
                <div class='boptions' v-if='emailshow'><input type="radio" id="email" name="bopt" :value="emaildisplay"
                        @click="bindoption=2" v-model="bindopt">
                    email : <label for="email">{{emaildisplay}}</label></div>
                <div class='boptions' v-else>
                    <router-link :to="{ path: '/personal-center/information',query: { bind: 'email' }}" tag="button">
                        Bind Email</router-link>
                </div>
            </div>
            <div v-if="bindoption==1" class="bindoption">
                <!-- <vue-tel-input v-if="!phoneNumberShow" v-bind="bindProps2" v-on:country-changed="countryChanged"
                v-model="cardInput.phone">
                </vue-tel-input>
                <vue-tel-input v-if="phoneNumberShow" v-bind="bindProps" v-on:country-changed="countryChanged"
                    v-model="cardInput.phone">
                </vue-tel-input> -->
                <div class="form-group">
                    <label for="">Mã xác minh SMS</label>
                    <input type="text" v-model="cardInput.code" @input="checkInput()"
                        placeholder="Vui lòng nhập mã xác minh SMS" v-on:keypress="isNumber($event, cardInput.code)">
                    <button v-bind:class="codeBtnDisabled" :disabled="codeBtnDisabled"
                        @click="sendCode()">{{ codeString }}</button>
                </div>
                <div class="border"></div>
                <div class="reminder">
                    Số điện thoại sẽ giúp bạn lấy lại tài khoản của mình và cũng là cơ sở quan trọng để nhân viên dịch
                    vụ
                    khách hàng xác nhận danh tính của bạn.
                </div>
                <div class="gold-border"></div>
                <div class="confirm-cont">
                    <button v-bind:class="{ disabled: confirmBtn }" :disabled="confirmBtn" @click="submit(1)">xác
                        nhận</button>
                </div>
            </div>
            <div v-if="bindoption==2" class="bindoption">
                <div class="form-group">
                    <label for="">Mã xác minh Email</label>
                    <input type="number" pattern="[0-9]*" v-model="ecode" @input="checkInput()"
                        placeholder="Vui lòng nhập mã xác minh Email">
                    <button v-bind:class="codeBtnDisabled2" :disabled="codeBtnDisabled2"
                        @click="sendemailCode()">{{ codeString2 }}</button>
                </div>
                <div class="border"></div>
                <div class="reminder">
                    Số điện thoại sẽ giúp bạn lấy lại tài khoản của mình và cũng là cơ sở quan trọng để nhân viên dịch
                    vụ
                    khách hàng xác nhận danh tính của bạn.
                </div>
                <div class="gold-border"></div>
                <div class="confirm-cont">
                    <button v-bind:class="{ disabled: confirmBtn2 }" :disabled="confirmBtn2" @click="submit(2)">xác
                        nhận</button>
                </div>
            </div>
            <div v-if="bindoption==3" class="bindoption">
                <!-- <div class='bindone'>Please bind phone or bind email.</div> -->
                <div class='bindone'>Please bind email.</div>
            </div>
        </div>

        <div class="selection-cont" v-if="popupShow">
            <div class="cont">
                <div class="bank-selection">
                    <div class="header">
                        <div class="title">Chọn ngân hàng</div>
                        <span @click="popupShow=false">Hủy</span>
                    </div>
                    <div class="body">
                        <ul>
                            <li @click="selected(items.name, index)" v-for="(items, index) in banks" v-bind:key="index">
                                <img :src="getImg(index)" alt="">
                                <span>{{ items.name }}</span></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>


        <div class="bottomPop" v-if="bottomPop">
            <div class="dim" @click="bottomPop=false"></div>
            <ul>
                <li @click="unBind()">xóa bỏ</li>
                <li @click="bottomPop=false">hủy bỏ</li>
            </ul>
        </div>

    </div>
</template>

<script>
    const axios = require('axios')
    const config = require('./../config/index.js')
    import Spinner from './../components/Spinner.vue'
    import LogOutSpinner from './../components/LogOutSpinner.vue'

    // import {
    //     VueTelInput
    // } from 'vue-tel-input'
    export default {
        components: {
            // VueTelInput,
            Spinner,
            LogOutSpinner
        },
        data() {
            return {
                prevActiveLoad: null,
                cardNameShow: true,
                phoneNumberShow: true,
                firstLoad: 1,
                bindProps: {
                    disabled: true
                },
                bindProps2: {
                    validCharactersOnly: true
                },
                isSpinner: false,
                spinMessage: '',
                failResponse: false,
                isSpinner2: false,
                spinMessage2: '',
                failResponse2: false,
                cards: {},
                cardInput: {
                    code: '',
                    phone: this.$store.state.phone,
                    name: this.$store.state.name,
                    cardNo: '',
                    selectedBank: '',
                    selectedBankID: '',
                    bankAddress: '',
                    countryCode: ''
                },
                mobilestored: this.$store.state.mobilestore,
                emailstored: this.$store.state.emailstore,
                codeString: 'Gửi mã xác minh',
                codeBtnDisabled: false,
                time: 60,
                codeString2: 'Gửi mã xác minh',
                codeBtnDisabled2: false,
                time2: 60,
                mobile: null,
                count: 0,
                step1: false,
                step2: false,
                popupShow: false,
                bottomPop: false,
                toDeleteID: null,
                bindoption: null,
                bindopt: '',
                mobileshow: false,
                emailshow: false,
                mobiledisplay: '',
                emaildisplay: '',
                ecode: '',
                // banks: {
                //     115: {
                //         name: 'Techcombank',
                //         sign: 'TCB'
                //     },
                //     116: {
                //         name: 'Sacombank',
                //         sign: 'SAC'
                //     },
                //     117: {
                //         name: 'Vietcombank',
                //         sign: 'VCB'
                //     },
                //     118: {
                //         name: 'Asia Commercial Bank',
                //         sign: 'ACB'
                //     },
                //     119: {
                //         name: 'DongA Bank',
                //         sign: 'DAB'
                //     },
                //     120: {
                //         name: 'Vietin Bank',
                //         sign: 'VTB'
                //     },
                //     121: {
                //         name: 'Bank for Investment and Development of Vietnam',
                //         sign: 'BIDV'
                //     },
                //     122: {
                //         name: 'Eximbank Vietnam',
                //         sign: 'EXB'
                //     },
                //     123: {
                //         name: 'Bank Central Asia',
                //         sign: 'BCA'
                //     },
                //     124: {
                //         name: 'Bank Negara Indonesia',
                //         sign: 'BNI'
                //     },
                //     125: {
                //         name: 'Bank Rakyat Indonesia',
                //         sign: 'BRI'
                //     },
                //     126: {
                //         name: 'Mandiri Bank',
                //         sign: 'MDR'
                //     },
                //     127: {
                //         name: 'CIMB Niaga',
                //         sign: 'CIMBN'
                //     },
                //     128: {
                //         name: 'Vietnam Prosperity Joint-Stock Commercial Bank',
                //         sign: 'VP'
                //     },
                //     129: {
                //         name: 'Military Commercial Joint Stock Bank',
                //         sign: 'MB'
                //     },
                //     130: {
                //         name: 'Tien Phong Commercial',
                //         sign: 'TPB'
                //     },
                //     131: {
                //         name: 'AGRI BANK',
                //         sign: 'AGRI'
                //     },
                //     132: {
                //         name: 'Lien Viet Post Joint Stock Commercial Bank',
                //         sign: 'LienVietPostBank'
                //     },
                //     133: {
                //         name: 'Saigon–Hanoi Commercial Joint Stock Bank',
                //         sign: 'SHB'
                //     },
                //     134: {
                //         name: 'Vietnam Public Joint-stock Commercial Bank',
                //         sign: 'PVcombank'
                //     },
                //     135: {
                //         name: 'SAIGON BANK',
                //         sign: 'SAIGON BANK'
                //     },
                //     136: {
                //         name: 'Southeast Asia Commercial Joint Stock Bank',
                //         sign: 'SeABank'
                //     },
                //     137: {
                //         name: 'An Binh Commercial Join Stock Bank',
                //         sign: 'ABBank'
                //     },
                //     138: {
                //         name: 'Bac A Bank',
                //         sign: 'BACABANK'
                //     },
                //     139: {
                //         name: 'Viet Capital Bank',
                //         sign: 'VietCapitalBank'
                //     },
                //     140: {
                //         name: 'Vietnam Maritime Commercial Joint Stock Bank',
                //         sign: 'MSB'
                //     },
                //     141: {
                //         name: 'Kien Long Commercial Joint Stock Bank',
                //         sign: 'KienLongBank'
                //     },
                //     142: {
                //         name: 'Nam A Commercial Joint Stock Bank',
                //         sign: 'NAMABANK'
                //     },
                //     143: {
                //         name: 'National Citizen Commercial Joint Stock Bank',
                //         sign: 'NCB'
                //     },
                //     144: {
                //         name: 'HD Bank',
                //         sign: 'HDBank'
                //     },
                //     145: {
                //         name: 'Orient Commercial Joint Stock Bank',
                //         sign: 'OCB'
                //     },
                //     146: {
                //         name: 'Vietnam International Commercial Joint Stock Bank',
                //         sign: 'VIB'
                //     },
                //     147: {
                //         name: 'Sai Gon Joint Stock Commercial Bank',
                //         sign: 'SCB'
                //     },
                //     148: {
                //         name: 'Saigon Bank for Industry and Trade',
                //         sign: 'SGB'
                //     },
                //     149: {
                //         name: 'VietABank',
                //         sign: 'VAB'
                //     },
                //     150: {
                //         name: 'BAOVIET Bank',
                //         sign: 'BVB'
                //     },
                //     151: {
                //         name: 'Vietnam Thuong Tin Commercial Joint Stock Bank',
                //         sign: 'VietBank'
                //     },
                //     152: {
                //         name: 'Petrolimex Group Commercial Joint Stock Bank',
                //         sign: 'PGBan'
                //     }
                // },
                 banks: {
                    501: {
                        name: 'USDT TRC20',
                        sign: 'USDT'
                    }
                },
                nextBtn: true,
                confirmBtn: true
            }
        },
        mounted() {
            var self = this
            self.getCards()
            // if (self.$store.state.cards == null || self.$store.state.cards == undefined) {
            //     self.getCards()
            // }

            self.cards = self.$store.state.cards
            self.cardInput.name = self.$store.state.name
            self.cardInput.phone = self.$store.state.mobile
            self.mobilestored = self.$store.state.mobilestore
            self.emailstored = self.$store.state.emailstore

    
            if (self.cards.length > 0) {
                self.firstLoad = 2
            } else {
                self.firstLoad = 1
            }






        },
        methods: {
            getbindopt() {
                var self = this

                self.mobiledisplay = self.mobilestored
                self.emaildisplay = self.emailstored


                if(self.emailstored == null && self.mobilestored == null) {
                    
                    self.bindoption = 3
                    self.bindopt = ""

                } else {
                    
                if (self.mobilestored === '' && self.emailstored === '') {
                    self.bindoption = 3
                } else if (self.mobilestored === '' && self.emailstored !== '') {
                    self.bindoption = 2
                    self.bindopt = self.emailstored
                } else if (self.mobilestored !== '' && self.emailstored === '') {
                    self.bindoption = 1
                    self.bindopt = self.mobilestored
                } else {
                    self.bindoption = 1
                    self.bindopt = self.mobilestored
                }
                if (self.mobilestored === '') {
                    self.mobileshow = false
                } else {
                    self.mobileshow = true
                }
                if (self.emailstored === '') {
                    self.emailshow = false
                } else {
                    self.emailshow = true
                }
                }



            },
            checkState() {
                var self = this
                if (self.cardInput.name == null || self.cardInput.name == '') {
                    self.cardNameShow = false
                } else {
                    self.cardNameShow = true
                }

                if (self.cardInput.phone == null || self.cardInput.phone == '') {
                    self.phoneNumberShow = false
                } else {
                    self.phoneNumberShow = true
                }
            },
            isNumber(event, cardNumber) {
                if (!/\d/.test(event.key) &&
                    (event.key !== /\./.test(cardNumber))
                )
                    return event.preventDefault();
            },
            unBind() {
                var self = this
                self.bottomPop = false
                self.isSpinner = true
                var base =
                    config.baseApi + '?merchant=' + config.merchant + '&serial=' + config.unbindBankSerial +
                    '&sign=' +
                    config.sign(config.merchant, config.unbindBankSerial, config.md5Key) + '&param='

                var param = config.encryptAES('card_id=' + this.toDeleteID, config.aes)
                axios.get(base + param)
                    .then(function (response) {
                        // handle success

                        self.isSpinner = true
                        self.failResponse = true
                        self.spinMessage = response.data.message

                        setTimeout(() => {
                            self.failResponse = false
                            self.isSpinner = false
                            self.spinMessage = ""
                            if (response.data.success) {
                                self.getCards()
                            }
                        }, 2000);

                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error);
                    });
            },
            edit(id) {
                this.toDeleteID = id
                this.bottomPop = true
            },
            submit(bind) {
                var self = this
                self.isSpinner = true
                var base =
                    config.baseApi + '?merchant=' + config.merchant + '&serial=' + config.addBankSerial +
                    '&sign=' +
                    config.sign(config.merchant, config.addBankSerial, config.md5Key) + '&param='

                // var param = config.encryptAES('bank=' + this.cardInput.selectedBankID + '&cardNum=' + this.cardInput
                //     .cardNo + '&name=' + this.cardInput.name + '&mobile=' + this.cardInput.countryCode + this
                //     .cardInput.phone.substr(1) + '&code=' + this.cardInput.code + '&type=2&branch_name=' + this
                //     .cardInput.bankAddress, config.aes)
                var param
                if (bind === 1) {
                    param = config.encryptAES('bank=' + this.cardInput.selectedBankID + '&cardNum=' + this.cardInput
                        .cardNo + '&name=' + this.cardInput.name + '&mobile=' + self.mobilestored + '&code=' + this
                        .cardInput.code + '&type=2&branch_name=' + this
                        .cardInput.bankAddress, config.aes)
                }
                if (bind === 2) {
                    param = config.encryptAES('bank=' + this.cardInput.selectedBankID + '&cardNum=' + this.cardInput
                        .cardNo + '&name=' + this.cardInput.name + '&email=' + self.emailstored + '&code=' + self
                        .ecode + '&type=1&branch_name=' + this
                        .cardInput.bankAddress, config.aes)
                }

                axios.get(base + param)
                    .then(function (response) {
                        // handle success

                        self.isSpinner = true
                        self.failResponse = true
                        self.spinMessage = response.data.message

                        setTimeout(() => {
                            self.failResponse = false
                            self.isSpinner = false
                            self.spinMessage = ""

                            if (response.data.success) {
                                self.firstLoad = 2
                                self.getCards()
                            }

                        }, 2000);

                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error);
                    });

            },
            sendCode() {
                var self = this
                self.isSpinner = true
                var base =
                    config.baseApi + '?merchant=' + config.merchant + '&serial=' + config.verificationCodeSerial +
                    '&sign=' +
                    config.sign(config.merchant, config.verificationCodeSerial, config.md5Key) + '&param='

                // var param = config.encryptAES('mobile=' + this.cardInput.countryCode + this.cardInput.phone.substr(1),
                //     config.aes)
                var param = config.encryptAES('mobile=' + self.mobilestored,
                    config.aes)
                axios.get(base + param)
                    .then(function (response) {
                        // handle success

                        self.isSpinner = true
                        self.failResponse = true
                        self.spinMessage = response.data.message

                        setTimeout(() => {
                            self.failResponse = false
                            self.isSpinner = false
                            self.spinMessage = ""
                        }, 2000);

                        if (response.data.success) {
                            self.codeBtnDisabled = true
                            var inter = setInterval(() => {
                                self.time = self.time - 1
                                self.codeString = self.time + 's'
                                if (self.time == 0) {
                                    self.codeBtnDisabled = false
                                    self.codeString = "Gửi mã xác minh"
                                    self.time = 60
                                    clearInterval(inter)
                                }
                            }, 1000);
                        }

                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error);
                    });

            },
            sendemailCode() {
                var self = this
                self.isSpinner = true
                var base =
                    config.accountAuth.baseApi + '?vendor=' + config.accountAuth.vendor + '&cmd=' + config.accountAuth
                    .verifyemailcodeBankCMD

                axios.get(base)
                    .then(function (response) {
                        // handle success

                        self.isSpinner = true
                        self.failResponse = true
                        self.spinMessage = response.data.errorMessage

                        setTimeout(() => {
                            self.failResponse = false
                            self.isSpinner = false
                            self.spinMessage = ""
                        }, 2000);

                        if (response.data.errorCode === 0) {
                            self.codeBtnDisabled2 = true
                            var inter = setInterval(() => {
                                self.time2 = self.time2 - 1
                                self.codeString2 = self.time2 + 's'
                                if (self.time2 == 0) {
                                    self.codeBtnDisabled2 = false
                                    self.codeString2 = "Gửi mã xác minh"
                                    self.time2 = 60
                                    clearInterval(inter)
                                }
                            }, 1000);
                        }

                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error);
                    });

            },
            checkInput() {
                if (this.cardInput.name == '' || this.cardInput.bankAddress == '' || this.cardInput.selectedBank ==
                    '' || this.cardInput.selectedBankID == '' || this.cardInput.cardNo == '') {
                    this.nextBtn = true
                } else {
                    this.nextBtn = false
                }

                if (this.cardInput.phone == '' || this.cardInput.code == '') {
                    this.confirmBtn = true
                } else {
                    this.confirmBtn = false
                }
                if (this.emailstored == '' || this.ecode == '') {
                    this.confirmBtn2 = true
                } else {
                    this.confirmBtn2 = false
                }
            },
            selected(name, id) {
                this.cardInput.selectedBankID = id
                this.cardInput.selectedBank = name
                this.popupShow = false
                this.checkInput()
            },
            addNewBank() {
                var self = this
                if(self.emailstored === null || self.emailstored === '') {
                    this.prevActiveLoad = this.firstLoad
                    this.firstLoad = 4
                    this.bindoption = 3
                    this.bindopt = ""  
                } else {
                    this.cardInput.name = ''
                    this.checkState()
                    this.prevActiveLoad = this.firstLoad
                    this.firstLoad = 3
                }
                
            },
            back() {

                if (this.prevActiveLoad == null) {

                    this.cards = this.$store.state.cards
                    this.cardInput.name = this.$store.state.name
                    this.cardInput.phone = this.$store.state.mobile
                    this.getCards()
                    this.checkState()
                } else {
                    this.firstLoad = this.prevActiveLoad
                }
            },
            next() {

                this.cardInput.phone = this.$store.state.mobile
                this.checkState()

                this.getbindopt()
                this.firstLoad = 4
            },
            countryChanged(country) {
                this.cardInput.countryCode = country.dialCode
            },
            getImg(pic) {
                return require('./../assets/img/vnd_banks/' + pic + '.png')
            },
            getCards() {
                var self = this
                self.isSpinner2 = true
                axios.get(
                        config.baseApi + '?merchant=' + config.merchant + '&serial=' + config.cardListSerial +
                        '&sign=' +
                        config.sign(config.merchant, config.cardListSerial, config.md5Key) + '&param='
                    )
                    .then(function (response) {
                        // handle success
                        if (response.data.success) {
                            self.name = response.data.data.name
                            self.cards = response.data.data.cards
                            self.mobile = response.data.data.mobile
                            self.count = response.data.data.count
                            self.isSpinner2 = false
                            self.$store.dispatch('saveContact', {
                                mobile: self.mobile,
                                name: self.name
                            })
                            self.$store.dispatch('saveCards', response.data.data.cards)

                            if (self.cards.length > 0) {
                                self.firstLoad = 2
                            } else {
                                self.firstLoad = 1
                            }

                        } else {
                            console.log(response.data)
                        }

                        if (response.data.errorCode == 21) {

                            self.isSpinner2 = true
                            self.failResponse2 = true
                            self.spinMessage2 = response.data.errorMessage

                        }

                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error);
                    });
            }
        },
        // beforeRouteEnter(to, from, next) {
        //     next((vm) => {
        //         if(from.name == 'Withdraw'){
        //             vm.checkState()
        //            vm.firstLoad = 3
        //         } else {
        //             vm.getCards()
        //             vm.checkState()
        //         }
        //     });
        // }
    }
</script>
<style lang="less">
    .no-bank {
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        top: 50%;
        width: 200px;
        text-align: center;
        transform: translateY(-50%);

        img {
            width: 150px;
            display: inline-block;
        }

        button {
            width: 150px;
            height: 35px;
            margin-top: 40px;
            background-color: #00a500;
            border: none;
            // background-image: url('./../assets/img/personal-center/add-button-bg.png');
            // background-repeat: no-repeat;
            // background-size: 100% 100%;
            border-radius: 3px;
            color: #fff;
        }
    }

    .with-bank {
        margin-top: 10px;

        .header {
            line-height: 40px;
            height: 40px;
            display: flex;
            width: 100%;
            position: relative;
            background-color: rgba(144, 144, 144, .2);

            h3 {
                color: #333;
                font-size: 14px;
                margin-left: 15px;
            }

            button {
                width: 20px;
                position: absolute;
                right: 10px;
                top: 10px;
                height: 20px;
                background-color: rgba(255, 255, 255, .5);
                border: none;
                background-image: url('./../assets/img/games/plus.png');
                background-repeat: no-repeat;
                background-size: 80% 80%;
                background-position: 2px 2px;
            }
        }

        .bank-cards {
            margin-top: 20px;
            text-align: center;
            // padding: 0 35px;

            .card-cont {
                width: 33.33%;
                float: left;
                text-align: center;

                .card {
                    position: relative;
                    display: inline-block;
                    width: 94%;
                    height: 90px;
                    background-color: #6eb75c;
                    background-repeat: no-repeat;
                    background-size: 100% 100%;
                    border-radius: 12px;
                    padding: 10px 3%;
                    text-align: left;

                    .icon {
                        width: 20px;
                        height: 20px;
                       // position: absolute;
                       // left: 10px;
                       // top: 10px;
                        border-radius: 100%;
                        background-color: #FFF;
                        text-align: left;
                        margin-bottom: 10px;
                        display: inline-block;

                        img {
                            display: inline-block;
                            width: 100%;
                            vertical-align: middle;
                        }
                    }

                    h4 {
                        color: #FFF;
                        // position: absolute;
                        // left: 12px;
                        // top: 50px;
                        display: inline-block;
                        margin-left: 5px;
                        font-size: 11px;
                    }
                    .card-name {
                        color: #fff;
                        // position: absolute;
                        // left: 12px;
                        // top: 36px;
                        font-size: 11px;
                        text-align: left;
                        margin-bottom: 5px;
                    }
                    .card-number {
                        font-size: 13px;
                        color: #FFF;
                        // position: absolute;
                        // bottom: 10px;
                        // left: 20%;
                    }

                    .edit {
                        position: absolute;
                        bottom: 13px;
                        right: 10px;
                        height: 15px;
                        width: 15px;
                        top: 14px;
                        background-image: url('./../assets/img/personal-center/edit-icon.png');
                        background-repeat: no-repeat;
                        background-size: 100% 100%;
                    }
                }
            }
        }
    }

    .add-card-1 {
        margin-top: 10px;

        .header {
            line-height: 40px;
            // height: 1px;
            display: flex;
            width: 100%;
            position: relative;
            background-color: rgba(255, 255, 255, .2);

            h3 {
                color: #333;
                font-size: 14px;
                margin-left: 15px;
            }
        }

        .row {
            display: flex;

            .col {
                margin-bottom: 5px;

                &:first-child {
                    width: 30%;
                    padding-left: 20px;
                }
            }
        }

        .form {
            color: #333;
            margin-top: 10px;

            label {
                margin-top: 10px;
                display: inline-block;
            }

            input {
                color: #333;
                background-color: transparent;
                height: 30px;
                width: 250px;
                display: inherit;
                line-height: 35px;
                text-align: center;
                border: none;
                // background-image: url('./../assets/img/personal-center/input-bg.png');
                // background-repeat: no-repeat;
                // background-size: 100% 100%;
                outline: none;
                background-color: #fff;
                border: 1px solid #666666;
                border-radius: 5px;
            }

            .custom-select {
                color: #333;
                background-color: transparent;
                height: 35px;
                width: 250px;
                line-height: 35px;
                text-align: center;
                border: none;
                // background-image: url('./../assets/img/personal-center/input-bg.png');
                // background-repeat: no-repeat;
                // background-size: 100% 100%;
                outline: none;
                position: relative;
                background-color: #fff;
                border: 1px solid #666666;
                border-radius: 5px;

                img {
                    position: absolute;
                    left: 10px;
                    top: 5px;
                    width: 15px;
                    background-color: #FFF;
                    border-radius: 100%;
                    padding: 5px;
                }

                div {
                    text-overflow: ellipsis;
                    overflow: hidden;
                    width: 83%;
                    margin-left: 17%;
                    white-space: nowrap;
                    text-align: left;
                }
            }

            p {
                margin-top: 5px;
                margin-bottom: 5px;
                width: 250px;
                font-size: 9px;
                text-align: justify;
            }

            .btn-cont {
                margin: 10px 0;
                width: 250px;

                button {
                    height: 30px;
                    background-color: transparent;
                    border: none;
                    width: 100px;
                    // background-image: url('./../assets/img/personal-center/next-button-bg.png');
                    // background-repeat: no-repeat;
                    // background-size: 100% 100%;
                    margin: 0 10px;
                    background: #52bf52;
                    border-radius: 5px;
                }
            }
        }
    }

    .add-card-2 {
        margin-top: 10px;

        .header {
            line-height: 40px;
            height: 40px;
            display: flex;
            width: 100%;
            position: relative;
            background-color: rgba(255, 255, 255, .3);

            h3 {
                color: #FFF;
                font-size: 14px;
                margin-left: 15px;
            }
        }

        .row {
            display: flex;

            .col {
                margin-bottom: 5px;
            }
        }

        .bindone {
            margin-left: 20px;
            color: #ff0000;
            font-size: 18px;
            margin-top: 30px;
        }

        .bindopt {
            height: 30px;
            color: #333;
            margin-bottom: 8px;
            display: inline-table;
            margin-left: 10px;

            .boptions {
                display: block;
                width: 100%;
                line-height: 30px;

                input {
                    margin-right: 10px;
                    position: relative;
                    top: 1px;
                }

                label {
                    color: #333;
                    font-size: 16px;
                }

                button {
                    height: 30px;
                    background-color: transparent;
                    border: none;
                    width: 100px;
                    // background-image: url('./../assets/img/personal-center/next-button-bg.png');
                    // background-repeat: no-repeat;
                    // background-size: 100% 100%;
                    background-color:#00a500;
                    margin: 0 10px;
                    color: #fff;
                }
            }
        }

        .vue-tel-input {
            width: 100%;
            height: 30px;
            line-height: 30px;
            background-color: transparent;
            border: none;
            outline: none;
            box-shadow: none;
            background-color: rgba(255, 255, 255, .2);

            input {
                outline: none;
                color: #FFF;

                &:focus {
                    outline: none;
                    outline: 0;
                    box-shadow: none !important;
                    background-color: transparent;
                }
            }

            span {
                display: inline-block;
            }

            .vti__dropdown {
                z-index: 1;
                background-color: rgba(255, 255, 255, .1);
            }

            .vti__flag {
                display: inline-block;
            }

            .vti__selection {
                display: flex;
            }

            .vti__input {
                background-color: transparent !important;
                position: relative;
            }
        }

        .form-group {
            height: 50px;
            background-color: rgba(255, 255, 255, .1);
            position: relative;
            padding: 10px 0;

            label {
                margin-left: 10px;
                color: #333;
            }

            input {
                padding-left: 10px;
                color: #333;
                left: 0;
                border: none;
                background-color: transparent;
                outline: none;
                width: 100%;
                height: 30px;
                line-height: 30px;
                position: absolute;
                bottom: 10px;
                font-size: 16px;
            }

            button {
                position: absolute;
                right: 10px;
                bottom: 15px;
                // background-image: url('./../assets/img/personal-center/next-button-bg.png');
                // background-repeat: no-repeat;
                // background-size: 100% 100%;
                height: 35px;
                line-height: 35px;
                padding: 0 10px;
                border: none;
                background-color:#00a500;
                color:#fff;
            }
        }

        .border {
            height: 3px;
            width: 100%;
            background-color: rgba(255, 255, 255, .5);
        }

        .reminder {
            background-color: rgba(255, 255, 255, .5);
            padding: 0 1px;
            color: #333;
            padding: 8px 0px;
        }

        .gold-border {
            height: 2px;
            background-color:#999;
            margin-bottom: 10px;
        }

        .confirm-cont {
            padding: 2px 0;
            text-align: center;
            background-color: rgba(255, 255, 255, .1);

            button {
                background-color:#00a500;
                border: none;
                // background-image: url('./../assets/img/personal-center/long-button-bg.png');
                // background-repeat: no-repeat;
                // background-size: 100% 100%;
                height: 35px;
                width: 30%;
                line-height: 35px;
                color: #fff;
            }
        }

    }

    .selection-cont {
        width: 95%;
        height: 95%;
        position: fixed;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        right: 0;
        margin: auto;
        z-index: 1000;

        .bank-selection {
            border-radius: 10px 10px 0 0;
            background-color:#fff;
            background-size: 100% 100%;
            position: absolute;
            color: #333;
            // background-image: url(./../assets/img/personal-center/content.png);
            width: 100%;
            height: 100%;
            left: 0;
            bottom: 0;
            z-index: 20 00;
            overflow: hidden;
            animation: anim .3s ease;

            @keyframes anim {
                0% {
                    top: 100%;
                }

                100% {
                    top: 10%;
                }
            }

            .header {

                background-color: #ddd;
                height: 73px;


                position: relative;
                display: -webkit-box;
                display: -webkit-flex;
                display: -ms-flexbox;
                /* display: flex; */
                -webkit-box-pack: justify;
                -webkit-justify-content: space-between;
                -ms-flex-pack: justify;
                justify-content: space-between;
                -webkit-box-align: center;
                -webkit-align-items: center;
                -ms-flex-align: center;
                align-items: center;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                -webkit-box-shadow: 0 0.04rem 0.08rem 0 rgba(0, 0, 0, .05);
                box-shadow: 0 0.04rem 0.08rem 0 rgba(0, 0, 0, .05);
                z-index: 10;

                .title {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    font-size: 16px;
                    font-weight: 400;
                    text-align: center;
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 29px;
                }

                span {
                    position: absolute;
                    top: 29px;
                    left: 15px;
                    color: #16cc00;
                }
            }

            .body {

                height: 80%;
                bottom: 0;
                width: 100%;
                position: absolute;
                display: block;
                overflow: auto;

                ul {
                    overflow: auto;
                    margin: 0 10px;

                    li {
                        justify-content: space-between;
                        padding-right: 15px;
                        height: 50px;
                        line-height: 50px;
                        padding-left: 15px;
                        border-bottom: 1px solid #ccc;
                        -webkit-box-sizing: border-box;
                        box-sizing: border-box;
                        text-align: left;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;

                        span {
                            margin-left: 10px;
                        }

                        img {
                            vertical-align: middle;
                            width: 30px;
                        }
                    }
                }
            }
        }
    }

    .bottomPop {
        overflow: hidden;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: 100%;
        height: 100%;
        z-index: 1000;
        background-color: transparent;

        .dim {
            width: 100%;
            height: 100%;
            top: 0;
        }

        ul {
            background-color: #ddd;
            // background-image: url(./../assets/img/personal-center/content.png);
            width: 100%;
            background-size: 100% 100%;
            height: 110px;
            font-size: 15px;
            position: absolute;
            bottom: 0;
            animation: anim .3s ease;

            li {
                padding-left: 10px;
                height: 50px;
                line-height: 50px;
                list-style-type: none;
                width: 100%;
                color: #56576e;
                background-color: rgba(255, 255, 255, .1);

                &:first-child {
                    color: #db6372;
                    margin-bottom: 10px;
                }
            }
        }

        @keyframes anim {
            0% {
                bottom: -165px;
            }

            100% {
                bottom: 0;
            }
        }
    }

    @media only screen and (min-width: 992px) {
        .with-bank {
            .bank-cards {
                .card-cont {
                    .card {
                        height: 150px !important;

                        .icon {
                            height: 40px;
                            width: 40px;
                        }

                        h4 {
                            // left: 61px;
                            // top: 71px;
                            font-size: 16px;
                        }
                        .card-name {
                            // left: 61px;
                            // top: 49px;
                            font-size: 16px;
                        }
                        .card-number {
                            font-size: 14px;
                        }

                        .edit {
                            right: 20px;
                            height: 20px;
                            width: 20px;
                        }
                    }
                }
            }
        }
    }

    @media (orientation: portrait) {
        // .selection-cont {
        //     width: 95vh;
        //     height: 95vw;
        //     left: 5vw;
        // }
    }
    @media only screen and (max-width: 767px) and (orientation : portrait)  {
        .add-card-1 {
            .form {
                label {
                    font-size: 14px;
                }
                input {
                    width: 98%;
                }
                .custom-select {
                    width: 98%;
                }
                p {
                    width: 96%;
                    font-size: 9px;
                }
            }
            .row {
                .col { width: 69%;}
                .col:first-child {
                    padding-left: 2%;
                }
            }
        }
    }
    @media only screen and (max-width: 413px) and (orientation : portrait)  {
         .add-card-1 {
            .form {
                label {
                    font-size: 13px;
                }
            }
         }
    }
    @media only screen and (max-width: 374px) and (orientation : portrait)  {
         .add-card-1 {
            .form {
                label {
                    font-size: 11px;
                }
            }
         }
    }
</style>